<h1 mat-dialog-title> Editar Usuário </h1>
<div mat-dialog-content>
    <form #f="ngForm">
        <div class="input-container">
            <mat-form-field>
                <mat-label> Nome: </mat-label>
                <input matInput name="name" ngModel="{{ user?.name }}">
            </mat-form-field>
            <mat-form-field class="input-margin-top">
                <mat-label> Email: </mat-label>
                <input matInput name="email" ngModel="{{ user?.email }}">
            </mat-form-field>
            <mat-form-field class="input-margin-top">
                <mat-label> Loja: </mat-label>
                <input matInput name="store" ngModel="{{ user?.store }}" disabled>
            </mat-form-field>
            <mat-form-field class="input-margin-top">
                <mat-label> GoogleID: </mat-label>
                <input matInput name="googleId" ngModel="{{ user?.googleId }}" disabled>
            </mat-form-field>
            <mat-form-field class="input-margin-top">
                <mat-label> Created At: </mat-label>
                <input matInput name="createdAt" ngModel="{{ user?.createdAt | date: 'dd/MM/yyyy H:mm:ss'}}" disabled>
            </mat-form-field>
        </div>
        <div class="input-margin">
            <mat-checkbox labelPosition="before" name="active" color="primary" [checked]="user?.active" ngModel="{{ user?.active == 1 ? 1 : 0 }}"> Active </mat-checkbox>
            <ng-container *ngIf="currentUser?.admin == 1"> 
                <mat-checkbox labelPosition="before" name="admin" color="primary" [checked]="user?.admin" ngModel="{{ user?.admin == 1 ? 1 : 0 }}" style="margin-left: 20px;"> Admin </mat-checkbox>
            </ng-container>
        </div>
        <div class="portlet portlet-sortable box grey-salsa">
            <div class="portlet-title ui-sortable-handle ">
                <div class="caption">
                    <i class="fa fa-key"></i>Permissões do usuário
                </div>
            </div>
            <div class="portlet-body">
                <div class="table-scrollable">
                    <table class="table table-hover">
                        <thead> 
                            <tr> 
                                <th> </th>
                                <th width="70px"> Leitura </th>
                                <th width="70px"> Criação </th>
                                <th width="70px"> Alteração </th>
                                <th width="70px"> Exclusão </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container ngModelGroup="roles"> 
                                <tr ngModelGroup="api"> 
                                    <th> API </th>
                                    <td> <mat-checkbox name="read" color="primary" [checked]="permissions?.api.read" [(ngModel)]="permissions?.api.read"> </mat-checkbox> </td>
                                </tr>
                                <tr ngModelGroup="blacklist">
                                    <th> Blacklist </th>
                                    <td> <mat-checkbox name="read" color="primary" [checked]="permissions?.blacklist.read" [(ngModel)]="permissions?.blacklist.read"> </mat-checkbox> </td>
                                    <td> <mat-checkbox name="create" color="primary" [checked]="permissions?.blacklist.create" [(ngModel)]="permissions?.blacklist.create"> </mat-checkbox> </td>
                                    <td> <mat-checkbox name="update" color="primary" [checked]="permissions?.blacklist.update" [(ngModel)]="permissions?.blacklist.update"> </mat-checkbox> </td>
                                    <td> <mat-checkbox name="delete" color="primary" [checked]="permissions?.blacklist.delete" [(ngModel)]="permissions?.blacklist.delete"> </mat-checkbox> </td>
                                </tr>
                                <ng-container ngModelGroup="reports"> 
                                <tr> <th> Relatórios </th> </tr>
                                <tr> 
                                    <th> Domínios mais recorrentes </th>
                                    <td> <mat-checkbox name="domainRead" color="primary" [checked]="permissions?.reports.domainRead" [(ngModel)]="permissions?.reports.domainRead"> </mat-checkbox></td>
                                </tr>
                                <tr> 
                                    <th> Estatísticas de envio </th>
                                    <td> <mat-checkbox name="statsRead" color="primary" [checked]="permissions?.reports.statsRead" [(ngModel)]="permissions?.reports.statsRead"> </mat-checkbox></td>
                                </tr>
                                <tr> 
                                    <th> Dados de envio </th>
                                    <td> <mat-checkbox name="dataRead" color="primary" [checked]="permissions?.reports.dataRead" [(ngModel)]="permissions?.reports.dataRead"> </mat-checkbox></td>
                                </tr>
                                </ng-container>
                                <tr  ngModelGroup="users"> 
                                    <th> Usuários </th>
                                    <td> <mat-checkbox name="read" color="primary" [checked]="permissions?.users.read" [(ngModel)]="permissions?.users.read"> </mat-checkbox></td>
                                    <td> <mat-checkbox name="create" color="primary" [checked]="permissions?.users.create" [(ngModel)]="permissions?.users.create"> </mat-checkbox></td>
                                    <td> <mat-checkbox name="update" color="primary" [checked]="permissions?.users.update" [(ngModel)]="permissions?.users.update"> </mat-checkbox></td>
                                    <td> <mat-checkbox name="delete" color="primary" [checked]="permissions?.users.delete" [(ngModel)]="permissions?.users.delete"> </mat-checkbox></td>
                                </tr>
                                <tr  ngModelGroup="templates"> 
                                    <th> Templates </th>
                                    <td> <mat-checkbox name="read" color="primary" [checked]="permissions?.templates.read" [(ngModel)]="permissions?.templates.read"> </mat-checkbox></td>
                                    <td> <mat-checkbox name="create" color="primary" [checked]="permissions?.templates.create" [(ngModel)]="permissions?.templates.create"> </mat-checkbox></td>
                                    <td> <mat-checkbox name="update" color="primary" [checked]="permissions?.templates.update" [(ngModel)]="permissions?.templates.update"> </mat-checkbox></td>
                                    <td> <mat-checkbox name="delete" color="primary" [checked]="permissions?.templates.delete" [(ngModel)]="permissions?.templates.delete"> </mat-checkbox></td>
                                </tr>
                                <tr  ngModelGroup="lojas"> 
                                    <th> Lojas </th>
                                    <td> <mat-checkbox name="read" color="primary" [checked]="permissions?.lojas.read" [(ngModel)]="permissions?.lojas.read"> </mat-checkbox></td>
                                </tr>
                                <tr ngModelGroup="settings">
                                    <th> Settings </th>
                                    <td> <mat-checkbox name="read" color="primary" [checked]="permissions?.settings.read" [(ngModel)]="permissions?.settings.read"> </mat-checkbox></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close> Cancel </button>
    <button mat-button [mat-dialog-close]="false" (click)="updateUser(f)"> Editar </button>
</mat-dialog-actions>
