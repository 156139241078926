import { Component, Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserDialog, UpdateUserDialog, DeleteUserDialog } from '../dialog';
import { UserModel } from '../interfaces';
import { ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'users',
    templateUrl: './user.component.html'
})

export class UsersComponent {
    public term: string;
    public p: number;
    public userList$ = new BehaviorSubject<UserModel[]>([]);
    public title: string = "Usuários";
    public key: string = "name";
    public reverse: boolean = false;

    private store: string;

    constructor(private userService: UserService, private dialog: MatDialog, private cdr: ChangeDetectorRef) { 
        this.store = sessionStorage.getItem("store") || '';
    }

    ngOnInit() {
        this.userService.showUsersByStore(this.store)
        .subscribe({
            next: (result: UserModel[]) => {
                this.userList$.next(result);
            },
            error: (err) => {
                console.error('Error fetching users', err);
            }
        });
    } 

    sort(key: string) {
        this.key = key;
        this.reverse = !this.reverse;
    }

    openCreateUserDialog() { 
        this.dialog
            .open(CreateUserDialog)
            .afterClosed().subscribe(() => {
                setTimeout(() => {
                    this.userService.showUsersByStore(this.store)
                        .subscribe((result: UserModel[]) => {
                            this.userList$.next(result);
                            this.cdr.detectChanges();
                        });
                }, 1000);
            });
    }

    openUpdateUserDialog(userId: string) {
        this.dialog
            .open(UpdateUserDialog, { data: userId })
            .afterClosed().subscribe(() => {
                this.userService.showUsersByStore(this.store)
                    .subscribe((result: UserModel[]) => {
                        this.userList$.next(result);
                        this.cdr.detectChanges();
                    });
            });
    }

    openDeleteUserDialog(userId: string) { 
        this.dialog
            .open(DeleteUserDialog, { data: userId })
            .afterClosed().subscribe(() => {
                this.userService.showUsersByStore(this.store)
                    .subscribe((result: UserModel[]) => {
                        this.userList$.next(result);
                        this.cdr.detectChanges();
                    });
            });
    }
}

@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    transform(items: any[], filterBy: string): any[] {
        if (!items) return [];
        if (!filterBy || filterBy.length === 0) return items;
        return items.filter(item => {
            return Object.keys(item).some(key => {
                if (item[key] && typeof item[key] === 'string') {
                    return item[key].toLowerCase().includes(filterBy.toLowerCase());
                }
                return false;
            });
        });
    }
}