import { NgModule } from '@angular/core'
import { PortletCaption, PortletActions } from '.'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { PortletTitle } from './title/portlet-title.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [ 
        PortletCaption,
        PortletActions,
        PortletTitle
     ],
    exports: [
        PortletCaption,
        PortletActions,
        PortletTitle
    ]
})

export class PortletModule { }