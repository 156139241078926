import { Component, Pipe, PipeTransform } from '@angular/core';
import { NgForm, FormControl } from '@angular/forms'
import { NgxSpinnerService } from 'ngx-spinner'
import { ReportService, DateHandlerService } from '../../services'
import { SentEmailsModel } from '../../interfaces/sending-data.interface';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'sending-data',
    templateUrl: './sending-data.component.html',
    styleUrls: ['./sending-data.component.css']
})

export class SendingDataComponent {
    public status: number = 2
    public data$ = new BehaviorSubject<SentEmailsModel[]>([]);
    public currentDay: any
    public yesterday: any
    public today: any
    public value: any
    
    // Variáveis utilizadas na paginação e ordenaçõ
    public term: string
    public page: number
    public formField = "date"
    public reverse = true

    constructor(
        private spinner: NgxSpinnerService, 
        private reportService: ReportService, 
        private dateService: DateHandlerService
    ) {
        this.currentDay = new Date()
        this.yesterday = this.dateService.convertDateToLocaleString('en-US', this.dateService.AddDays(-1))
        this.today = this.dateService.convertDateToLocaleString('en-US', this.dateService.AddDays(0))

        this.value = new FormControl(this.today) 
    }

    ngOnInit() {
        this.spinner.show()
        this.reportService.getSentData()
            .subscribe((httpResponse: SentEmailsModel[]) => {
                this.data$.next(httpResponse);
                this.spinner.hide()
            })
    }

    GetFilteredEmails(f: NgForm) {
        let formFields = { ...f.form.value } // <-- cria uma cópia do formulário, dessa forma não altera os valores dele
        
        formFields.startDate = formFields.startDate.toLocaleDateString('pt-BR');
        formFields.endDate = formFields.endDate.toLocaleDateString('pt-BR');
        
        console.log(formFields)
        let params = this.GetParameters(formFields)
        console.log(params)

        this.spinner.show()
        this.reportService.getSentData(params)
            .subscribe((httpResponse: SentEmailsModel[]) => {
                this.data$.next(httpResponse);
                this.spinner.hide()
            })
    }

    private GetParameters(fields: any) {
        let queryArray = Object.keys(fields).map(field => {
            return `${field}=${fields[field]}`      // <-- Return query parameters like 'startDate=01/01/2019'
        })        
        return queryArray.join('&');                // <-- Join the parameters with '&' like 'startDate=01/01/2019&startEnd=03/03/2019'
    }

    sort(formField: string) {
        this.reverse = !this.reverse
        this.formField = formField
    }
}

@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    transform(items: any[], filterBy: string): any[] {
        if (!items) return [];
        if (!filterBy || filterBy.length === 0) return items;
        return items.filter(item => {
            return Object.keys(item).some(key => {
                if (item[key] && typeof item[key] === 'string') {
                    return item[key].toLowerCase().includes(filterBy.toLowerCase());
                }
                return false;
            });
        });
    }
}