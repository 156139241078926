import { NgModule } from '@angular/core'
import { SharedModule } from '../../../shared/shared.module'
import { ReportService, DateHandlerService } from './services'

import { 
    CommonDomainsComponent,
    FilterByPipe,
    SendingDataComponent,
    SentStatsComponent 
} from './page'

@NgModule({
    declarations: [
        CommonDomainsComponent,
        SendingDataComponent,
        SentStatsComponent,
        FilterByPipe
    ],
    imports: [
        SharedModule
    ],
    providers: [
        ReportService,
        DateHandlerService
    ]
})

export class ReportsModule {}