<page-nav-ul>
    <page-nav-li [link]="true" path="/home/dashboard" [icon]="true" label="Stampel"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="true" label="Relatórios"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="false" label="Domínios mais recorrentes"> </page-nav-li>
</page-nav-ul>
<div class="row">
    <div class="col-md-6">
        <div class="portlet light bordered">
            <div class="portlet-body">
                <div portlet-title>
                    <portlet-caption subject={{title}}></portlet-caption>
                </div>
                <hr />
                <div>
                    <div class="grid-mvc">
                        <div class="grid-wrap">
                            <table class="table table-hover grid-table">   
                                <thead>
                                    <tr>
                                        <th width="50%" (click)="sort('domain')"> 
                                            <span> Domínio </span>
                                            <span class="glyphicon sort-icon" *ngIf="formField == 'domain'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse }"> </span>
                                        </th>
                                        <th width="50%" (click)="sort('occurences')"> 
                                            <span> Total de e-mails na blacklist </span>
                                            <span class="glyphicon sort-icon" *ngIf="formField == 'occurences'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse }"> </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="grid-row" *ngFor="let domain of domains | orderBy: formField: reverse">
                                        <td class="grid-cell" data-name="Domain"> {{ domain.domain }} </td>
                                        <td class="grid-cell" data-name="Occurrences">
                                            {{ domain.occurences }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
