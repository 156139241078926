import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ErrorListModel } from '../interfaces/errorList.interface'

import { environment } from '../../../../../environments/environment'
const apiURL = environment.apiURL;
const API_ENDPOINT_QUEUE = `${apiURL}/api/ErrorList/GetErrorList`
const API_ENDPOINT_RETRY = `${apiURL}/api/ErrorList/SendToRetry`
const API_ENDPOINT_DELETE = `${apiURL}/api/ErrorList/Delete`

@Injectable()
export class ErrorListService {

  constructor(
    private http: HttpClient
  ) { }

  public requestErrorMails() {
    return this.http.get<ErrorListModel>(API_ENDPOINT_QUEUE)
  }

  public deleteErrorMail(id: string) {
    return this.http.delete(`${API_ENDPOINT_DELETE}/?mailId=${id}`);
  }

  public sentToRetry(id: string) {
    let body = null;
    return this.http.post(`${API_ENDPOINT_RETRY}/?mailId=${id}`, body);
  }
}
