import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { StoreService } from '../../services';
import { StoresModel } from '../../interfaces';

@Component({
    selector: 'create-store-dialog',
    templateUrl: './create-store.dialog.html',
    styleUrls: [ './create-store.dialog.css' ]
})

export class CreateStoreDialog {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private storeService: StoreService 
    ) { }

    public postStore(form: NgForm) {
        let newStore: StoresModel

        newStore = { 
            name: form.value["name"],
            email: form.value["email"],
            website: form.value["website"],
            createdAt: "",
            updatedAt: "",
            active: form.value["active"] == 1 ? 1 : 0
        }

        this.storeService.createStore(newStore)
            .subscribe(_ => {})
    }
}
