import { NgModule } from '@angular/core'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatButtonModule } from '@angular/material/button'

import { 
    LayoutComponent,
    NavMenuComponent,
    HeaderComponent,
    FooterComponent
 } from './page'

import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    declarations: [
        LayoutComponent,
        NavMenuComponent,
        HeaderComponent,
        FooterComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        MatSidenavModule,
        MatButtonModule,
        SharedModule
    ]
})

export class LayoutModule {}