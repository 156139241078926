import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { TemplatesService } from '../../services/templates.service';

@Component({
    selector: 'create-template',
    templateUrl: './create-template.dialog.html',
    styleUrls: ['./create-template.dialog.css']
})

export class CreateTemplateDialog {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private templateService: TemplatesService
    ) {}

    PostTemplate(input: NgForm) {
        let json = JSON.stringify(input.form.value)

        // Cria um template fazendo um POST na API. Após a chamada, redireciona para a página anterior ("/home/templates")
        // Endpoint da chamada: "https://msgit.bringit.com.br/api/Templates/PostTemplate"
        this.templateService.postTemplate(json)
            .subscribe( _ => {})
    }
}