import { Component, Pipe, PipeTransform } from '@angular/core'
import { ErrorListService } from '../services/errorList.service'
import { ErrorListModel, ErrorEmailModel } from '../interfaces/errorList.interface'
import { MatDialog } from '@angular/material/dialog'
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'errorList',
  templateUrl: './errorList.component.html',
  providers: [ErrorListService]
})

export class ErrorListComponent {
  public term: string;
  public page: number;
  public data$ = new BehaviorSubject<{ [emails: string]: Array<ErrorEmailModel> }>(null);
  public total: any;

  constructor(
    private errorListService: ErrorListService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.refresh()
  }

  refresh() {
    this.errorListService.requestErrorMails()
      .subscribe((httpResponse: ErrorListModel) => {
        if (httpResponse === null) {
          this.data$.next(null);
          this.total = 0
        }
        else {
          this.data$.next(httpResponse.emails);
          this.total = httpResponse.emails.length;
        }
      })
  }

  DeleteEmail(mail: ErrorEmailModel) {
    this.errorListService.deleteErrorMail(mail.id).subscribe(_ => {
      this.refresh();
    })
  }

  SendToRetry(mail: ErrorEmailModel) {
    this.errorListService.sentToRetry(mail.id).subscribe(_ => {
      this.refresh();
    })
  }

  public decodeB64String(item: string): string {
    if (!item) return "-";
    if (item.includes("?utf-8?B?")) {
        try {
            const match = item.match(/=\?utf-8\?B\?(.*?)\?=/);
            if (match && match[1]) {
                let encodedString = match[1];
                return decodeURIComponent(escape(window.atob(encodedString)));
            }
        } catch (e) {
            console.error("Error decoding Base64 string:", e);
            return "-";
        }
    }
    return item;
  }
}

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
  transform(items: any[], filterBy: string): any[] {
      if (!items) return [];
      if (!filterBy || filterBy.length === 0) return items;
      return items.filter(item => {
          return Object.keys(item).some(key => {
              if (item[key] && typeof item[key] === 'string') {
                  return item[key].toLowerCase().includes(filterBy.toLowerCase());
              }
              return false;
          });
      });
  }
}