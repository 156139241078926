import { Component, HostBinding } from '@angular/core'
import { AccessService } from '../../../../auth/services';

@Component({
    selector: '[header]',
    templateUrl: './header.component.html'
})

export class HeaderComponent {

    public photo = sessionStorage.getItem('userPhoto')
    public email = sessionStorage.getItem('email')
    public name = sessionStorage.getItem('name')

    constructor(private accessService: AccessService) { 

    }

    @HostBinding('class') class = "page-header navbar navbar-fixed-top navbar-fixed-top mxnavbar bg-blue-bringit"

    public logout() {
        this.accessService.signOut();
    }
}