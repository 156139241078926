import { NgModule } from '@angular/core'
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from '@abacritt/angularx-social-login'
import { SharedModule } from '../../shared/shared.module'
import { LoginComponent } from './pages'
import { AccessService } from './services';

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        SocialLoginModule,
        SharedModule
    ],
    providers: [
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider('442962591213-a952c4b1849qkakql8jv38i1jlgeupe8.apps.googleusercontent.com')
            }
          ],
          onError: (err) => {
            console.error(err);
          }
        } as SocialAuthServiceConfig,
      },
        AccessService
    ]
})

export class AuthModule { }
