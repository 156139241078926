import { Component, OnInit, Input } from '@angular/core'
import { BlackStatsService } from '../../services/blstats.service'
import { BlackStatsModel } from '../../interfaces/blstats.interface'
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'bl-stats',
  templateUrl: './blstats.component.html',
  providers: [ BlackStatsService ]
})

export class BlackStatsComponent implements OnInit {
  @Input('stats') stats: BlackStatsModel
  
  constructor(
  ) { }

  ngOnInit() { 
    
  }
};