<div class='page-footer-inner info'>
    <span class="font-weight-light copyright">
         &copy; 
         {{ year }},
         made with 
        <i class="fa fa-heart" style="color: #1dd5a7"></i>
         by
         <a style="color: #1dd5a7 !important; font-weight: bold" href="https://nordware.io/" target="_blank"> Nordware </a>
    </span>
</div>
<div class="scroll-to-top">
    <i class="icon-arrow-up"></i>
</div>