import { NgModule } from '@angular/core'
import { SharedModule } from '../../../shared/shared.module'

import { ErrorListComponent, FilterByPipe } from './page'

@NgModule({
    declarations: [
    ErrorListComponent,
    FilterByPipe
    ],
    imports: [
        SharedModule
    ]
})

export class ErrorListModule {}
