import { ErrorHandler } from '@angular/core'

export class ErrorLogger implements ErrorHandler { 
    constructor() {}

    handleError(error: any) {
        console.error(
            error.filename,
            error.lineNumber, ':', 
            error.columnNumber, '\n',
            error.message,
            error.rejection
        )
    }
}