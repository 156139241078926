<h1 mat-dialog-title> Cadastrar Novo Usuário </h1>
<div mat-dialog-content>
    <form #f="ngForm">
        <div class="input-container">
            <mat-form-field>
                <mat-label> Nome: </mat-label>
                <input matInput name="name" ngModel>
            </mat-form-field>
            <mat-form-field class="input-margin-top">
                <mat-label> Email: </mat-label>
                <input matInput name="email" ngModel>
            </mat-form-field>
            <mat-form-field>
                <mat-label class="control-label labelSize16"> Loja </mat-label>
                <mat-select name="store" ngModel>
                    <mat-option *ngFor="let s of stores" [value]="removeSpacesAndToLower(s.name)"> {{ s.name }} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="input-margin">
            <mat-checkbox labelPosition="before" name="active" color="primary" ngModel> Active </mat-checkbox>
            <mat-checkbox labelPosition="before" name="admin" color="primary" style="margin-left: 20px;" ngModel> Admin </mat-checkbox>
        </div>
        <div class="portlet portlet-sortable box grey-salsa">
            <div class="portlet-title ui-sortable-handle ">
                <div class="caption">
                    <i class="fa fa-key"></i>Permissões do usuário
                </div>
            </div>
            <div class="portlet-body">
                <div class="table-scrollable">
                    <table class="table table-hover">
                        <thead> 
                            <tr> 
                                <th> </th>
                                <th width="70px"> Leitura </th>
                                <th width="70px"> Criação </th>
                                <th width="70px"> Alteração </th>
                                <th width="70px"> Exclusão </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container ngModelGroup="roles"> 
                                <tr ngModelGroup="api"> 
                                    <th> API </th>
                                    <td> <mat-checkbox name="read" color="primary" ngModel> </mat-checkbox> </td>
                                </tr>
                                <tr ngModelGroup="blacklist">
                                    <th> Blacklist </th>
                                    <td> <mat-checkbox name="read" color="primary" ngModel> </mat-checkbox> </td>
                                    <td> <mat-checkbox name="create" color="primary" ngModel> </mat-checkbox> </td>
                                    <td> <mat-checkbox name="update" color="primary" ngModel> </mat-checkbox> </td>
                                    <td> <mat-checkbox name="delete" color="primary" ngModel> </mat-checkbox> </td>
                                </tr>
                                <ng-container ngModelGroup="reports"> 
                                <tr> <th> Relatórios </th> </tr>
                                <tr> 
                                    <th> Domínios mais recorrentes </th>
                                    <td> <mat-checkbox name="domainRead" color="primary" ngModel> </mat-checkbox></td>
                                </tr>
                                <tr> 
                                    <th> Estatísticas de envio </th>
                                    <td> <mat-checkbox name="statsRead" color="primary" ngModel> </mat-checkbox></td>
                                </tr>
                                <tr> 
                                    <th> Dados de envio </th>
                                    <td> <mat-checkbox name="dataRead" color="primary" ngModel> </mat-checkbox></td>
                                </tr>
                                </ng-container>
                                <tr  ngModelGroup="users"> 
                                    <th> Usuários </th>
                                    <td> <mat-checkbox name="read" color="primary" ngModel> </mat-checkbox></td>
                                    <td> <mat-checkbox name="create" color="primary" ngModel> </mat-checkbox></td>
                                    <td> <mat-checkbox name="update" color="primary" ngModel> </mat-checkbox></td>
                                    <td> <mat-checkbox name="delete" color="primary" ngModel> </mat-checkbox></td>
                                </tr>
                                <tr  ngModelGroup="templates"> 
                                    <th> Templates </th>
                                    <td> <mat-checkbox name="read" color="primary" ngModel> </mat-checkbox></td>
                                    <td> <mat-checkbox name="create" color="primary" ngModel> </mat-checkbox></td>
                                    <td> <mat-checkbox name="update" color="primary" ngModel> </mat-checkbox></td>
                                    <td> <mat-checkbox name="delete" color="primary" ngModel> </mat-checkbox></td>
                                </tr>
                                <tr  ngModelGroup="lojas"> 
                                    <th> Lojas </th>
                                    <td> <mat-checkbox name="read" color="primary" ngModel> </mat-checkbox></td>
                                </tr>
                                <tr ngModelGroup="settings">
                                    <th> Settings </th>
                                    <td> <mat-checkbox name="read" color="primary" ngModel> </mat-checkbox></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close> Cancel </button>
    <button mat-button [mat-dialog-close]="false" (click)="postUser(f)"> Criar </button>
</mat-dialog-actions>
