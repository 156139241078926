import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../../../environments/environment'
const apiURL = environment.apiURL
const API_ENDPOINT_INSERT = `${apiURL}/api/Templates/PostTemplate`
const API_ENDPOINT_GET = `${apiURL}/api/Templates/GetTemplates`
const API_ENDPOINT_DELETE = `${apiURL}/api/Templates/DeleteTemplate`
const API_ENDPOINT_GETSingle = `${apiURL}/api/Templates/GetSingleTemplate`
const API_ENDPOINT_Update = `${apiURL}/api/Templates/UpdateTemplates`
const API_SENDMESSAGE = `${apiURL}/api/Message/SendMessage`

@Injectable()
export class TemplatesService {
    
    constructor(
        private http: HttpClient
    ) { }

    // Insert a template in 'msgit_templates'
    postTemplate(template: any) {
        return this.http.post(`${API_ENDPOINT_INSERT}`, template);
    }

    // Get all templates from 'msgit_templates'
    getAllTemplates() {
        return this.http.get(`${API_ENDPOINT_GET}`)
    }

    // Delete a template from 'msgit_templates'
    deleteTemplate(id: string) {
        return this.http.post(`${API_ENDPOINT_DELETE}?id=${id}`, null)
    }

    // Get a Single template from 'msgit_templates'
    getTemplateById(id: string) {
        return this.http.get(`${API_ENDPOINT_GETSingle}?id=${id}`)
    }

    // Update a template in 'msgit_templates'
    updateTemplate(changes: any, id: string) {
        return this.http.post(`${API_ENDPOINT_Update}?id=${id}`, changes)
    }

    // Send Template email 
    send(email: any) {
        return this.http.post(`${API_SENDMESSAGE}`, email)
    }
}