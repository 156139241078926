import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { PeriodStatsModel } from '../interfaces/dashboard.interface'

import { environment } from '../../../../../environments/environment';
const API_ENDOPOINT_PERIOD = `${environment.apiURL}/api/Home/GetStatsByPeriod`

@Injectable()
export class DashboardService {

    constructor(
        private http: HttpClient
    ) { }

    public getPeriodStats(period: string) {
        return this.http.get<PeriodStatsModel>(`${API_ENDOPOINT_PERIOD}?period=${period}`)
    }

    public getPeriodString(period: string): string {
        let periodString: string;
        
        switch(period) {
            case '7days': periodString = '7 dias'; break;
            case '15days': periodString = '15 dias'; break;
            case '3months': periodString = '3 meses'; break;
            case '6months': periodString = '6 meses'; break; 
        }

        return periodString;
    }
}