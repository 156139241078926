<page-nav-ul>
    <page-nav-li [link]="true" path="/home/dashboard" [icon]="true" label="Stampel"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="false" label="Black list"> </page-nav-li>
</page-nav-ul>

<div class="col-md-12">
    <div class="col-md-7 portlet light bordered">
        <div class="portlet-body">
            <div portlet-title>
                <portlet-caption subject={{title}}> Total: {{ (blacklist$ | async) && (blacklist$ | async)?.length > 0 }} </portlet-caption>
            </div>
            <hr />
            <div class='row'>
                <div class='col-md-6 col-sm-6'> </div>
                <div class='col-md-6 col-sm-6'> 
                    <div style="text-align: right">
                        <label> 
                            Filtrar:
                            <!-- Angular syntax: "term" é utilizado no *ngFor como um filtro -->
                            <input type="text" [(ngModel)]="term" [ngModelOptions]="{standalone: true}" class="form-control input-sm input-small input-inline" placeholder="Search Email" >
                        </label> 
                    </div>
                </div>
            </div> 
            <div class="table-scrollable">
                <table class="table table-hover dataTable no-footer">
                    <thead>
                        <tr>

                            <!-- Angular syntax block -->
                            <th width="150px" (click)="sort('date')" style="position: relative; text-align: center;"> 
                                <span> Data de Entrada </span>
                                <span class="glyphicon sort-icon" *ngIf="formField =='date'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>                             
                            </th>
                            <th min-width="250px" (click)="sort('email')" class="fbx fbx-sp-btw"> 
                                <span> Email </span>
                                <span class="glyphicon sort-icon" *ngIf="formField =='email'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                            </th>
                            <!-- End of Angular syntax block -->

                            <th width="100px" style="position: relative; text-align: center;"> Ações </th>
                        </tr>
                    </thead>
                    <tbody>

                        <!-- Angular syntax block -->
                        <tr *ngFor='let item of (blacklist$ | async | filterBy: term) | orderBy: formField: reverse | paginate: { itemsPerPage: 20, currentPage: page }' [ngClass]="'gradeX'" [id]='item.id'>
                            <td style="position: relative; text-align: center;"> {{ item.date | date: 'dd/MM/yyyy H:mm:ss' }} </td>
                            <td> {{ item.email }} </td>
                            <td class="align-right" style="position: relative; text-align: center;">
                                <a (click)="diagnosticDialog(item.diagnostic)" class="btn btn-default" title="Editar"><span class="fa fa-info"></span></a>
                                <a (click)="deleteDialog(item)" class="btn btn-danger" title="Excluir"><span class="fa fa-trash"></span></a>
                            </td>
                        </tr>
                        <!-- End of Angular syntax block -->

                    </tbody>
                </table>
            </div>
            <pagination-controls (pageChange)="page = $event"> </pagination-controls>
        </div>
    </div>
    <div class="col-md-4">
        <div class="portlet light bordered">
            <div class="portlet-title tabbable-line">
                <div class="caption">
                    <span class="caption-subject font-dark bold uppercase"> Adicionar item na lista </span>
                </div>
            </div>
            <div class="portlet-body">
                <div class="form-horizontal">

                    <!-- Angular syntax block  -->
                    <form #f='ngForm' class="form-horizontal" (ngSubmit)="createDialog(f)">
                        <div class="form-group">
                            <div class="col-sm-6">
                                <input name="email" class="form-control" placeholder="example@example.com" ngModel>
                            </div>
                            <div class="col-sm-6">
                                <button class="btn stampel-color"> Salvar </button> 
                            </div>
                        </div>
                    </form>
                    <!-- End of Angular syntax block -->

                </div>
            </div>
        </div>
    </div>
</div>
