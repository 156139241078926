import { Inject, Component } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'diagnostic',
    templateUrl: './diagnostic.component.html'
})

export class DiagnosticComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public diagnostic: string
    ) { }
}
