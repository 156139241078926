import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BlackStatsModel } from '../interfaces/blstats.interface'

import { environment } from '../../../../../environments/environment'
const API_ENDOPOINT_BLACKLIST = `${environment.apiURL}/api/Home/GetStats`

@Injectable()
export class BlackStatsService {

    constructor(
        private http: HttpClient
    ) { }

    public getBlacklistStats() {
        return this.http.get<BlackStatsModel>(`${ API_ENDOPOINT_BLACKLIST }`)
    } 
}