import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../services';
import { UserModel, MapRoles } from '../../interfaces';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'update-user',
    templateUrl: './update-user.dialog.html',
    styleUrls: [ '../create/create-user.dialog.css' ]
})

export class UpdateUserDialog {
    
    public user: UserModel
    public permissions: MapRoles
    public currentUser: UserModel

    constructor(
        @Inject(MAT_DIALOG_DATA) public userId: string,
        private userService: UserService
    ) {}

    ngOnInit() { 
        let currentUserEmail = sessionStorage.getItem('email')
        this.userService.showUserByEmail(currentUserEmail)
            .subscribe((httpResponse: UserModel) => { 
                this.currentUser = httpResponse
            })

        this.userService.showUserById(this.userId)
            .subscribe((httpResponse: UserModel) => {
                this.user = httpResponse
                console.log(this.user)
                this.permissions = this.userService.mapUserPermissions(this.user["permissions"])
            })
    }

    public updateUser(form: NgForm) {
        let updateUser: UserModel
        let isAdmin = form.value["admin"]
        let permissions: string = this.userService.createPermissions(form.value["roles"], isAdmin)

        updateUser = {
            email: form.value["email"],
            name: form.value["name"],
            permissions: permissions,
            active: form.value["active"] ? 1 : 0,
            admin: form.value["admin"] ? 1 : 0,
            createdAt: this.user["createdAt"],
            googleId: this.user["googleId"],
            store: this.user["store"]
        }

        this.userService.updateUser(updateUser, this.userId)
            .subscribe(_ => {})
    }

}