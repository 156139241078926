import { Component, Pipe, PipeTransform } from '@angular/core'
import { NgForm } from '@angular/forms'
import { NgxSpinnerService } from 'ngx-spinner'
import { MatDialog } from '@angular/material/dialog'

import { BlacklistModel } from '../interfaces/blacklist.interface'
import { BlacklistService } from '../services/blacklist.service'
import { CreateEmailComponent, DeleteEmailComponent, DiagnosticComponent } from '../dialog';
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'blacklist',
  templateUrl: './blacklist.component.html'
})

export class BlacklistComponent {
  public title = "Blacklist"
  public blacklist$ = new BehaviorSubject<BlacklistModel[]>([]); 

  // Variáveis utilizadas na paginação 
  public term;
  public page: string = '0';
  public formField: string = "date"
  public reverse: boolean = true

  constructor(
    private blacklistService: BlacklistService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) { }

  /* ngOnInit() é chamado após o component ser inciado.
     Aqui, ele faz uma requisição GET em "https://msgit.bringit.com.br/api/Blacklist/GetBlacklist"
     que retorna um array de emails. 'this.spinner.show/hide()' mostra ou esconde o ícone 
     de loading <ngx-spinner> </ngx-spinner> */
  ngOnInit() {
    this.spinner.show()
    this.blacklistService.requestBlacklist().subscribe((httpResponse: BlacklistModel[]) => {
      this.blacklist$.next(httpResponse);
      this.spinner.hide()
    });
  }

  /* "DeleteEmail(id: string)" é chamada ao clicar no ícone de remover item.
         -- Parâmetros: [ id: string ] -> É o identificado do MongoDB (ObjectID).
      Faz uma requisição POST em "https://msgit.bringit.com.br/api/Blacklist/DeleteEmail"
      Após requisição, deleta o elemento <li> da View */
  DeleteEmail(id: string) {
    const element = document.getElementById(id);
    this.blacklistService.deleteEmailFromBlacklist(id).subscribe(_ => {
      element.parentNode.removeChild(element);
    })
  }

  InsertEmail(f: NgForm) {
    this.spinner.show()
    this.blacklistService.requestBlacklist().subscribe((httpResponse: BlacklistModel[]) => {
      setTimeout(() => {
        this.blacklist$.next(httpResponse);
        this.spinner.hide();
      }, 1000);
    });
  }

  /* "sort(formField: string)" é utilizado nas paginações feitas pelo módulo NgxPaginationModule
         -- Parâmetros: [ formField: string ] -> É o nome do campo definido no formulário (ex: sort('email') 
         ou sort('date')) */
  sort(formField: string) {
    this.formField = formField
    this.reverse = !this.reverse
  }

  deleteDialog(item: BlacklistModel) {
    let currentBlacklist = this.blacklist$.getValue();
    let index = currentBlacklist.indexOf(item);
    this.dialog.open(DeleteEmailComponent, { data: currentBlacklist[index] });
  }

  diagnosticDialog(diagnostic: string) {
    this.dialog.open(DiagnosticComponent, { data: diagnostic })
  }

  createDialog(f: NgForm) {
    let email = f.form.value.email
    this.dialog.open(CreateEmailComponent, { data: email })
  }
}

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
  transform(items: any[], filterBy: string): any[] {
      if (!items) return [];
      if (!filterBy || filterBy.length === 0) return items;
      return items.filter(item => {
          return Object.keys(item).some(key => {
              if (item[key] && typeof item[key] === 'string') {
                  return item[key].toLowerCase().includes(filterBy.toLowerCase());
              }
              return false;
          });
      });
  }
}