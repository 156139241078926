import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplatesModel } from '../../interfaces/templates.interface';
import { TemplatesService } from '../../services/templates.service';

@Component({
    selector: 'delete-template',
    templateUrl: './delete-template.dialog.html',
    styleUrls: [ './delete-template.dialog.css']
})

export class DeleteTemplateDialog {

    public templateData: TemplatesModel

    constructor(
        @Inject(MAT_DIALOG_DATA) public templateId: string,
        private templateService: TemplatesService
    ) {}

    ngOnInit() { 
        this.templateService.getTemplateById(this.templateId)
            .subscribe((httpResponse: TemplatesModel) => {
                this.templateData = httpResponse
            })
    }

    DeleteTemplate(templateId: string) { 
        this.templateService.deleteTemplate(templateId)
            .subscribe(_ => {})
    }
}