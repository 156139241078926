<page-nav-ul>
    <page-nav-li [link]="true" path="/home/dashboard" [icon]="true" label="Stampel"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="false" label="Queue"> </page-nav-li>
</page-nav-ul>

<div class="row">
    <div class="col-md-12">
        <div class="portlet light bordered">
            <div portlet-title>
                <portlet-caption subject="Fila de Emails"> Total: {{ total }} </portlet-caption>
            </div>
            <div class='row fbx' style="margin: 15px 0;">
                <div class='col-md-6 col-sm-6'> 
                    <div style="text-align: left;">
                        <label style="margin: auto 0;"> 
                            Filtrar:
                            <input type="text" [(ngModel)]="term" class="form-control input-sm input-small input-inline" placeholder="Search Email" >
                        </label> 
                    </div>
                </div>
                <div class='col-md-6 col-sm-6' style="text-align: right;"> 
                    <a class='btn stampel-color' (click)="refresh()"> 
                        Atualizar 
                        <i class="icon-refresh"> </i> 
                    </a>
                </div>
            </div> 
            <div class="portlet-body" style="padding-top: 0px;">
                <div id="sample_1_wrapper" class="dataTables_wrapper no-footer">
                    <table class="table table-striped table-bordered table-hover table-checkable order-column dataTable no-footer" id="datatable-data" role="grid" aria-describedby="sample_1_info">
                        <thead>
                            <tr role="row">
                                <th style="width: 100px; text-align: left; padding-left: 15px"> nº </th>
                                <th style="width: 150px; text-align: left; padding-left: 15px"> Sender IP </th>
                                <th style="width: 200px; text-align: left; padding-left: 15px"> From </th>
                                <th style="width: 200px; text-align: left; padding-left: 15px"> To </th>
                                <th style="width: 450px; text-align: left; padding-left: 15px"> Subject </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="data == null" class="gradeX" role="row">
                                <td colspan="5" class="sorting_1"> Não há emails na fila </td>
                            </tr>
                            <tr *ngFor="let item of data | paginate: { itemsPerPage: 10, currentPage: page }" class="gradeX" role="row">
                                <td> {{ data.indexOf(item) }} </td>
                                <td style="text-align: left; padding-left: 10px"> {{ item.ip }} </td>
                                <td> {{ item.sender }}</td>
                                <td> {{ item.recipients[0] }} </td>
                                <td> {{ decodeB64String(item.subject) }} </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls (pageChange)="page = $event"> </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
