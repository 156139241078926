import { NgModule } from '@angular/core'
import { SharedModule } from '../../../shared/shared.module'
import { UserService } from './services'
import { StoreService } from '../stores/services'

import { FilterByPipe, UsersComponent } from './page'

import { CreateUserDialog, UpdateUserDialog, DeleteUserDialog } from './dialog'

@NgModule({
    declarations: [
        UsersComponent,
        CreateUserDialog,
        UpdateUserDialog,
        DeleteUserDialog,
        FilterByPipe
    ],
    imports: [
        SharedModule
    ],
    providers: [
        UserService,
        StoreService
    ]
})

export class UsersModule { }