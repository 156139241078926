import { NgModule } from '@angular/core'
import { SharedModule } from '../../../shared/shared.module'
import { FilterByPipe, StoresComponent } from './page'
import { CreateStoreDialog, UpdateStoreDialog, DeleteStoreDialog } from './dialog'
import { StoreService } from './services';

@NgModule({
    declarations: [
        StoresComponent,
        CreateStoreDialog,
        UpdateStoreDialog,
        DeleteStoreDialog,
        FilterByPipe
    ],
    imports: [
        SharedModule
    ],
    providers: [
        StoreService
    ]
})

export class StoresModule {}