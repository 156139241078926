import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { StoresModel } from '../../interfaces';
import { StoreService } from '../../services';

@Component({
    selector: 'update-store',
    templateUrl: './update-store.dialog.html',
    styleUrls: [ './update-store.dialog.css' ]
})

export class UpdateStoreDialog {
    
    public store: StoresModel

    constructor(
        @Inject(MAT_DIALOG_DATA) public storeId: string,
        private storeService: StoreService
    ) {}

    ngOnInit() {
        this.storeService.getStoreById(this.storeId)
            .subscribe((httpResponse: StoresModel) => {
                this.store = httpResponse
            })
    }

    updateStore(f: NgForm) {
        let value = f.form.value
        console.log(value)
        let store: StoresModel

        store = { 
            name: value["name"],
            email: value["email"],
            website: value["website"],
            active: value["active"] == 1 ? 1 : 0
        }

        this.storeService.updateStore(store, this.storeId)
            .subscribe(_ => {})
    }
}