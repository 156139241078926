<h1 mat-dialog-title> Você tem certeza de que deseja deletar esta Loja? </h1>
<div mat-dialog-content>
    <dl class="dl-horizontal">
        <dt> Id: </dt>
        <dd> {{ storeId }} </dd>

        <dt> Nome: </dt>
        <dd> {{ store?.name }} </dd>

        <dt> Email: </dt>
        <dd> {{ store?.email }} </dd>

        <dt> Website: </dt>
        <dd> {{ store?.website }} </dd>

        <dt> CreatedAt: </dt>
        <dd> {{ store?.createdAt | date: 'dd/MM/yyyy H:mm:ss' }} </dd>

        <dt> UpdatedAt: </dt>
        <dd> {{ store?.updatedAt | date: 'dd/MM/yyyy H:mm:ss' }} </dd>

        <dt> Ativo: </dt> 
        <dd> <mat-checkbox name="active" [checked]="store?.active" disabled> </mat-checkbox> </dd>
    </dl>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" (click)="DeleteStore()"> Deletar </button>
</mat-dialog-actions>