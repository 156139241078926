import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from '../../services';
import { StoresModel } from '../../interfaces';

@Component({
    selector: 'delete-store',
    templateUrl: './delete-store.dialog.html'
})

export class DeleteStoreDialog {
    
    public store: StoresModel
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public storeId: string,
        private storeService: StoreService
    ) {}

    ngOnInit() {
        this.storeService.getStoreById(this.storeId)
            .subscribe((httpResponse: StoresModel) => {
                this.store = httpResponse
            })
    }

    DeleteStore() { 
        this.storeService.deleteStore(this.storeId)
            .subscribe( _ => {})
    }
}