<page-nav-ul>
    <page-nav-li [link]="true" path="/home/dashboard" [icon]="true" label="Stampel"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="true" label="Relatórios"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="false" label="Dados de envio"> </page-nav-li>
</page-nav-ul>
<div class="row">
    <div class="col-md-12">
        <div class="portlet light bordered report" style="padding-bottom: 0px;">
            <div portlet-title>
                <portlet-caption subject="Dados de envio"> 
                    Envios que passaram pelo msgIT, independente do status da entrega. <b>Intervalo padrão:</b> últimos 2 dias.
                </portlet-caption>
            </div>
            <div class="portlet-body" style="padding-top: 0px;">
                <div class="list-filter">
                    <div class="form-group col-md-12">
                        <label class="control-label"> Selecione abaixo uma opção de filtro: </label>
                        <div class="mt-radio-inline" style="padding-bottom: 5px;">
                            <label class="mt-radio">
                                <input name="optionsRadios" id="datarange" value="data" checked type="radio"> Por Email e Data
                                <span></span>
                            </label>
                        </div>
                        <hr style="border-top: 1px solid #d4d4d4;margin-top:0px;margin-bottom:0px;" />
                    </div>

                    <!-- Begin of Angular 'form' -->
                    <form #f='ngForm' (ngSubmit)="GetFilteredEmails(f)">
                        <mat-form-field class="form-group col-lg-4 col-md-4" appearance="outline">
                            <mat-label> Email  </mat-label>
                            <input matInput name="email" ngModel="" placeholder="example@example.com.br">
                        </mat-form-field>
                        <mat-form-field class="form-group col-lg-2 col-md-2" appearance="outline"> 
                            <mat-label> Date Start </mat-label>
                            <input matInput name="startDate" [(ngModel)]="yesterday" [matDatepicker]="startDate" placeholder="ex: 01/01/2019" [max]="currentDay"> 
                            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="form-group col-lg-2 col-md-2" appearance="outline">
                            <mat-label> Date End </mat-label>
                            <input matInput name="endDate" [(ngModel)]="today" [matDatepicker]="endDate" placeholder="ex: 03/03/2019" [max]="currentDay"> 
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="form-group col-md-2" appearance="outline">
                            <mat-label class="control-label labelSize16"> Status </mat-label>
                            <mat-select [(ngModel)]="status" name="status">
                                <mat-option [value]="2"> Default </mat-option>
                                <mat-option [value]="0"> Não enviado </mat-option>
                                <mat-option [value]="1"> Enviado </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="form-group col-md-1">
                            <button style="width: 120px;" class="btn stampel-color"> Buscar </button>
                        </div>
                    </form>
                    <!-- End of Angular 'form' -->
                    
                    <div class="clearfix"></div>
                </div>
                <div class='row'>
                    <div class='col-md-6 col-sm-6'> </div>
                    <div class='col-md-6 col-sm-6'> 
                        <div style="text-align: right">
                            <mat-form-field>
                                <mat-label> Filtrar </mat-label>
                                <input matInput [(ngModel)]="term" [ngModelOptions]="{standalone: true}" placeholder="keyword"> 
                            </mat-form-field>
                        </div>
                    </div>
                </div> 
                <div class="dataTables_wrapper no-footer">
                    <table class="table table-striped table-bordered table-hover table-checkable order-column dataTable no-footer">
                        <thead>
                            <tr role="row">
                                <th width="255px" style="text-align: left; padding-left: 15px;" (click)="sort('date')"> Data
                                    <span class="glyphicon" *ngIf="formField == 'date'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse }"> </span>     
                                </th>
                                <th width="186px" (click)="sort('subject')"> Assunto 
                                    <span class="glyphicon" *ngIf="formField == 'subject'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse }"> </span>
                                </th>
                                <th width="373px" (click)="sort('email')"> Destinatário 
                                    <span class="glyphicon" *ngIf="formField == 'email'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse }"> </span>
                                </th>
                                <th width="198px" (click)="sort('type')"> Status 
                                    <span class="glyphicon" *ngIf="formField == 'type'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse }"> </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="(data$ | async) && (data$ | async)?.length <= 0" class="gradeX" role="row">
                                <td> Nenhum registro encontrado - Desculpe. </td>
                            </tr>
                            <tr *ngFor="let item of (data$ | async | filterBy: term) | orderBy: formField: reverse | paginate: { itemsPerPage: 20, currentPage: page }" class="gradeX">
                                <td class="sorting_1" style="text-align: left; padding-left: 10px"> {{ item.date | date: 'dd/MM/yyyy H:mm:ss'}} </td>
                                <td> {{ item.subject }} </td>
                                <td> {{ item.email }}</td>
                                <td class="center">
                                    <span *ngIf="item.type == 0" style="min-width: 180px;" [ngClass]="'label' + ' label-sm ' + ' label-danger'">
                                        Bloqueado
                                    </span>
                                    <span *ngIf="item.type == 1" style="min-width: 180px;" [ngClass]="'label' + ' label-sm ' + ' label-success'">
                                        Enviado
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls (pageChange)="page = $event"> </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
