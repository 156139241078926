import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'period-stats',
  templateUrl: './periodstats.component.html',
})

export class PeriodStatsComponent implements OnInit {
  
  // Input -> Recebe atributos do componente pai (DashboardComponent)
  @Input('period') period: string;
  @Input('stats') stats: any;
  @Input('topSubjects') topSubjects: any;
  @Input('topReceivers') topReceivers: any;

  constructor( ) { }

  ngOnInit() { }
}