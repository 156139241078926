import { Component, OnInit, Input } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
    selector: 'echarts-graphic',
    templateUrl: './graph-stats.component.html'
})

export class GraphicComponent implements OnInit { 
    // Input -> Recebe atributos do componente pai (DashboardComponent)
    @Input('period') period: string;
    @Input('options') options: EChartsOption;
    
    constructor() { 
        
    }

    ngOnInit() {
        
    }
}