import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { SocialAuthService, SocialUser, GoogleLoginProvider } from '@abacritt/angularx-social-login'
import { AccessService } from '../../services'
import { ScriptService } from '../../../../shared/services';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'login',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
    title = "Login Page"
    private user: SocialUser;
    private loggedIn: boolean;

    private scriptTagName = 'script-login'
    private scriptPath = 'assets/scripts/login/login.js'

    constructor(
        private authService: SocialAuthService,
        private accessService: AccessService,
        private scriptService: ScriptService,
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private _document
    ) {
        this.scriptService.renderer2 = renderer2
    }

    ngOnInit() {
        this.authService.authState.subscribe((user) => {
            this.user = user;
            this.accessService.loginValidation(user);
            this.loggedIn = (user != null);
        });

        (window as any).handleCredentialResponse = this.handleCredentialResponse.bind(this);

        if (this.scriptService.tagExists(this.scriptTagName))
            this.scriptService.deleteTag(this.scriptTagName)
    }

    ngAfterViewInit() {
        this.scriptService.createScriptTag(this.scriptPath, this._document.body, this.scriptTagName)
    }

    handleCredentialResponse(response: any) {
        const decodedToken = this.decodeJwtResponse(response.credential);

        // Validação e login do usuário
        this.accessService.loginValidation(decodedToken);
    }

    decodeJwtResponse(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}
