<div class='page-head'> 
    <div class='row'>
        <div class='col-sm-12'> 
            <form [formGroup]="form" *ngIf="currentUser?.admin == 1">
                <mat-form-field class="btn-group pull-left">
                    <mat-label class="control-label"> Loja </mat-label>
                    <mat-select formControlName="stores">
                        <mat-option *ngFor="let s of stores" [value]="removeSpacesAndToLower(s.name)"> {{ s.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
            <div id='btnperiod' class='btn-group pull-right' style='margin-bottom: 20px;'>
                <a class='btn stampel-color dropdown-toggle' href='javascript:;' data-toggle='dropdown' aria-expanded='true'>
                    <i class='fa fa-calendar'> </i>
                    Período
                    <i class="fa fa-angle-down"></i>
                </a>
                <ul class='dropdown-menu pull-right'>
                    <li>
                        <a routerLink='/home/dashboard/7days' routerLinkActive="active">
                            Últimos 7 dias
                        </a>
                    </li>
                    <li>
                        <a routerLink='/home/dashboard/15days' routerLinkActive="active">
                            Últimos 15 dias
                        </a>
                    </li>
                    <li>
                        <a routerLink='/home/dashboard/3months' routerLinkActive="active">
                            Últimos 3 meses
                        </a>
                    </li>
                    <li>
                        <a routerLink='/home/dashboard/6months' routerLinkActive="active">
                            Últimos 6 meses
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class='row'>    
    <!-- Blacklist Statistics Component -->
    <bl-stats [stats]="blacklistStats"> </bl-stats>
    
    <!-- Statistics By Period Component -->
    <period-stats [stats]="stats" [period]="period" [topReceivers]="topReceivers" [topSubjects]="topSubjects"> </period-stats>
</div>

<echarts-graphic [options]="options" [period]="period"> </echarts-graphic>
<!-- Graphic Component -->