import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NgForm } from '@angular/forms'
import { UserModel } from '../../interfaces'
import { UserService } from '../../services'
import { StoreService } from '../../../stores/services'
import { StoresModel } from '../../../stores/interfaces';

@Component({ 
    selector: 'create-user-dialog',
    templateUrl: './create-user.dialog.html',
    styleUrls: [ './create-user.dialog.css' ]
})

export class CreateUserDialog {
    
    public stores: StoresModel[]

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        private userService: UserService,
        private storeService: StoreService
    ) { }

    ngOnInit() {
        this.storeService.getStores()
            .subscribe((httpResponse: StoresModel[]) => { 
                this.stores = httpResponse
            })
    }

    public postUser(form: NgForm) { 
        let newUser: UserModel
        let isAdmin = form.value["admin"]
        let permissions: string = this.userService.createPermissions(form.value["roles"], isAdmin)

        newUser = {
            email: form.value["email"],
            name: form.value["name"],
            googleId: '',
            createdAt: '',
            permissions: permissions,
            active: form.value["active"] ? 1 : 0,
            admin: form.value["admin"] ? 1 : 0,
            store: form.value["store"]
        }

        this.userService.createUser(newUser)
            .subscribe(_ => {})
    }

    public removeSpacesAndToLower(storeName: string) {
        return storeName
            .toLowerCase()
            .replace(" ", "")
    }
}