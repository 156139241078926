/* Módulos que serão utilizados pelos componentes do diretório 'app/Home'.
   São pacotes instalados via NPM e que ficam no diretório 'ClientApp/node_modules' 
   Pesquisar nome do módulo para verificar documentação. */
   import { NgModule } from '@angular/core';
   import { RouterModule } from '@angular/router';
   import { CommonModule } from '@angular/common';
   import { FormsModule } from '@angular/forms';
   
   // Routes
   import { HOME_ROUTES } from './home.routes';
   
   // Guards
   import { DashboardModule } from './dashboard/dashboard.module';
   import { QueueModule } from './queue/queue.module';
   import { ErrorListModule } from './errorList/errorList.module';
   import { ReportsModule } from './reports/reports.module';
   import { TemplatesModule } from './templates/templates.modules';
   import { UsersModule } from './users/users.module';
   import { LayoutModule } from './layout/layout.module';
   import { SettingsModule } from './settings/settings.modules';
   import { ApiModule } from './api/api.module';
   import { BlacklistModule } from './blacklist/blacklist.module';
   import { StoresModule } from './stores/stores.module';

   @NgModule({
       imports: [
           CommonModule,
           FormsModule,
           DashboardModule,
           QueueModule,
           ApiModule,
           BlacklistModule,
           ReportsModule,
           TemplatesModule,
           UsersModule,
           LayoutModule,
           SettingsModule,
           StoresModule,
           ErrorListModule,
           RouterModule.forChild(HOME_ROUTES)
       ],
       declarations: [],
       providers: [],
       exports: []
   })
   export class HomeModule { }   