import { Component, Pipe, PipeTransform } from '@angular/core'
import { QueueService } from '../services/queue.service'
import { QueueModel, EmailModel } from '../interfaces/queue.interface'

@Component({
    selector: 'queue',
    templateUrl: './queue.component.html',
    providers: [ QueueService ]
})

export class QueueComponent {
    public term: string;
    public page: number;
    public data: {[emails: string]: Array<EmailModel>};
    public total: any;

    constructor(
        private queueService: QueueService
    ) {}

    ngOnInit() {
        this.refresh()
    }

    refresh() {
        this.queueService.requestEmailsInQueue()
        .subscribe((httpResponse: QueueModel) => {
            if (httpResponse === null) {
                this.data = null
                this.total = 0
            }
            else {
                this.data = httpResponse.emails
                this.total = this.data.length
            }
        })
    }

    public decodeB64String(item: string): string { 
        if (item.includes("?utf-8?B?")) {
            let encodedString = item.match(/(\=\?utf-8\?B\?)(.*?)(\?\=)/)[2]
            return decodeURIComponent(escape(window.atob(encodedString)))
        }
        return item
    }
}

@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    transform(items: any[], filterBy: string): any[] {
        if (!items) return [];
        if (!filterBy || filterBy.length === 0) return items;
        return items.filter(item => {
            return Object.keys(item).some(key => {
                if (item[key] && typeof item[key] === 'string') {
                    return item[key].toLowerCase().includes(filterBy.toLowerCase());
                }
                return false;
            });
        });
    }
}