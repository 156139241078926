import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccessService } from './modules/auth/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  public loggedIn: boolean;

  constructor(private router: Router, private accessService: AccessService) {
    this.loggedIn = this.accessService.isLoggedIn();
    if(this.loggedIn) 
      router.navigate(['/home'])
    else 
      router.navigate(['/login'])
  }
}
