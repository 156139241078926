<div class='page-header-inner'>
    <div class='page-logo'>
        <a routerLink='/home/dashboard' routerLinkActive="active" style="position: relative"> 
            <div class='logo-default'>
                <embed src='assets/images/Stampel_5.svg' width="170" height="56">
            </div>
        </a>
        <div class='menu-toggler sidebar-toggler'> </div>
    </div>
    <a href='javascript:;' class='menu-toggler responsive-toggler' data-toggle='collapse' data-target='.navbar-collapse'> </a>
    <div class='page-top'>
        <div class='top-menu'>
            <ul class='nav navbar-nav pull-right'>
                <li class='dropdown dropdown-user dropdown-dark xsdropmenu' style="padding-left:0;">
                    <a href='javascript:;' class='dropdown-toggle' data-toggle='dropdown' data-hover='dropdown' data-close-others='true' aria-expanded='true'> 
                        <span class="username username-hide-on-mobile"> {{ name }} </span>
                        <img alt="" style="border: 0px;" class="img-circle xsuser" src="{{ photo }}">
                    </a>
                    <ul class="dropdown-menu dropdown-menu-default dropdown-stampel">
                        <li class="user-identification" style="display: flex;"> 
                            <div class="picture">
                                <img alt="" style="max-width: 100%;" class="img-circle" src="{{ photo }}">
                            </div> 
                            <div class="data">
                                <strong> {{ name }} </strong>
                                <span class="email"> {{ email }} </span>
                            </div>
                        </li>
                        <li class="action"> 
                            <a (click)="logout()" class="btn btn-default pull-right btn-exit"> 
                                Sair 
                            </a> 
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class='clearfix'> </div>
