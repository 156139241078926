<page-nav-ul>
    <page-nav-li [link]="true" path="/home/dashboard" [icon]="true" label="Stampel"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="false" label="Templates"> </page-nav-li>
</page-nav-ul>

<div class="row">
    <div class="col-md-12">
        <div class="portlet light bordered">
            <div portlet-title>
                <portlet-caption subject="Templates de Mensagem"> Criação de templates e envio de Emails </portlet-caption>
            </div>
            <div class='row' style="margin: 15px 0;">
                <div class='col-md-6 col-sm-6' style="text-align: left; padding: 0;"> 
                    <a (click)="openCreateTemplateDialog()" class='btn stampel-color'> 
                        Cadastrar 
                        <i class="icon-plus"> </i> 
                    </a>
                </div>
                <div class='col-md-6 col-sm-6'> </div>
            </div> 
            <div class="portlet-body">
                <div *ngIf="templates == null" class='panel-group accordion faq-content' style="text-align: center;"> 
                    <p> Não existe templates cadastrados </p>
                </div>
                <ng-container *ngIf="templates !== null">
                    <ng-container *ngFor="let template of templates; let i = index;">
                        <div class="panel-group accordion faq-content" id="{{ template.id }}">
                            <div class="panel panel-default"> 
                                <div class="panel-heading">
                                    <h4 class="panel-title"> 
                                        <a class="accordion-toggle row fbx" data-toggle="collapse" [attr.href]="'#collapse'+i"> 
                                            <div class="col-sm-12">
                                                <b> {{ template?.title }} </b>
                                            </div>
                                            <div class="align-right col-sm-3">
                                                <a (click)="openUpdateTemplateDialog(template.id)" class="btn btn-default" style="margin-right: 10px;">
                                                    <span class="fa fa-pencil"> </span>
                                                </a> 
                                            </div>
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapse{{i}}" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <div class="row" style="margin-bottom: 20px;">
                                            <div class="align-left col-sm-6">
                                                <b> Subject: </b> <span> {{ template?.subject }} </span>
                                            </div>
                                            <div class="align-right col-sm-6">
                                                <i> Created: {{ template?.createdAt }} </i>
                                            </div>
                                        </div> 
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <p style="white-space: pre-wrap; text-align: justify;">{{ template?.body }}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12" style="text-align: right;">
                                                <a (click)="openDeleteTemplateDialog(template.id)" type="button" class="btn btn-danger btn-remove" style="margin-right: 10px;">
                                                    Deletar
                                                    <span class="fa fa-trash"> </span>
                                                </a>
                                                <a (click)="openSendTemplateDialog(template.id)" class="btn btn-success">
                                                    Utilizar
                                                    <i class="icon-arrow-right"> </i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
