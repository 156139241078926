import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner'
import { ReportService } from '../../services'
import { CommonDomainsModel } from '../../interfaces/common-domains.interface';

@Component({
    selector: 'common-domains',
    templateUrl: './common-domains.component.html'
})

export class CommonDomainsComponent {
    public title: string = "Domínios não bloqueados mais recorrentes"
    public domains: CommonDomainsModel[]

    // Variáveis utilizadas nos filtros
    public formField: string = "occurences"
    public reverse: boolean = true 
    
    constructor(
        private spinner: NgxSpinnerService,
        private reportService: ReportService
    ) { }

    ngOnInit() {
        this.spinner.show();
        this.reportService.getCommonDomains()
            .subscribe((httpResponse: CommonDomainsModel[]) => {
                this.domains = httpResponse;
                this.spinner.hide()
            })
    }


    /* "sort(formField: string)" é utilizado nas tabelas feitas pelo módulo NgxPaginationModule
           -- Parâmetros: [ formField: string ] -> É o nome do campo que deverá ser ordenado (ex: sort('dommain') 
           ou sort('occurence')) */
    sort(formField: string) {
        this.formField = formField
        this.reverse = !this.reverse
    }
} 