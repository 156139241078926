/* Modules */
import { NgModule, ErrorHandler } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HomeModule } from './modules/home/home.module'
import { AuthModule } from './modules/auth/auth.module'

/* Interceptors */
import { ApiRequestsInterceptor } from './shared/interceptors'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

/* Services */
import { ScriptService } from './shared/services'
import { DialogService } from './shared/services'

/* Routes */
import { APP_ROUTES } from './app.routes'

// Components
import { AppComponent } from './app.component'
import { AuthGuard } from './modules/auth/services/auth-guard.service';
import { ErrorLogger } from './core';

import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    MatSnackBarModule,
    HttpClientModule,
    FormsModule,
    HomeModule,
    AuthModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule.forRoot(APP_ROUTES),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestsInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler, useClass: ErrorLogger
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'pt-BR'
    },
    AuthGuard,
    ScriptService,
    DialogService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
