<h1 mat-dialog-title> Editar Loja </h1>
<div mat-dialog-content>
    <form #f="ngForm">
        <div class="input-container">
            <mat-form-field>
                <mat-label> Nome: </mat-label>
                <input matInput name="name" ngModel="{{ store?.name }}">
            </mat-form-field>
            <mat-form-field class="input-margin-top">
                <mat-label> Email Atendimento: </mat-label>
                <input matInput name="email" ngModel="{{ store?.email }}">
            </mat-form-field>
            <mat-form-field class="input-margin-top">
                <mat-label> Website: </mat-label>
                <input matInput name="website" ngModel="{{ store?.website }}">
            </mat-form-field>
            <mat-form-field class="input-margin-top">
                <mat-label> Created At: </mat-label>
                <input matInput name="createdAt" ngModel="{{ store?.createdAt | date: 'dd/MM/yyyy H:mm:ss'}}" disabled>
            </mat-form-field>
            <mat-form-field *ngIf="store?.updatedAt != ''" class="input-margin-top">
                <mat-label> Last Update: </mat-label>
                <input matInput name="updatedAt" ngModel="{{ store?.updatedAt | date: 'dd/MM/yyyy H:mm:ss'}}" disabled>
            </mat-form-field>
        </div>
        <mat-checkbox class="input-margin-top" labelPosition="before" name="active" color="primary" [checked]="store?.active" ngModel="{{ store?.active == 1 ? 1 : 0 }}"> Active </mat-checkbox>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close> Cancel </button>
    <button mat-button [mat-dialog-close]="false" (click)="updateStore(f)"> Editar </button>
</mat-dialog-actions>