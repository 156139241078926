<h1 mat-dialog-title> Cadastrar Loja </h1>
<div mat-dialog-content>
    <form #f="ngForm">
        <div class="input-container">
            <mat-form-field>
                <mat-label> Nome: </mat-label>
                <input matInput name="name" ngModel>
            </mat-form-field>
            <mat-form-field>
                    <mat-label> Email de Atendimento: </mat-label>
                    <input matInput name="email" ngModel>
                </mat-form-field>
            <mat-form-field class="input-margin-top">
                <mat-label> Website: </mat-label>
                <input matInput name="website" ngModel>
            </mat-form-field>
        </div>
        <mat-checkbox class="input-margin-top" labelPosition="before" name="active" color="primary" ngModel> Active </mat-checkbox>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close> Cancel </button>
    <button mat-button [mat-dialog-close]="false" (click)="postStore(f)"> Criar </button>
</mat-dialog-actions>