import { Component, OnInit, Renderer2, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { AccessService } from '../../../../auth/services'
import { ScriptService } from '../../../../../shared/services';
import { DOCUMENT } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'nav-menu',
    templateUrl: './nav-menu.component.html'
})

export class NavMenuComponent implements OnInit, AfterViewInit {
    @ViewChild('sidenav') sidenav: MatSidenav
    
    public links = { 
        "Dashboard": {
            path: '/home',
            icon: 'icon-home',
            id: 'menu-dashboard'
        },
        "API": {
            path: '/home/api',
            icon: 'icon-link',
            id: 'menu-api'
        },
        "Lojas": {
            path: '/home/stores',
            icon: 'icon-handbag',
            id: 'menu-stores'
        },
        "Queue": {
            path: '/home/queue',
            icon: 'icon-layers',
            id: 'menu-queue'
        },
        "Emails com erro": {
            path: '/home/errorList',
            icon: 'icon-layers',
            id: 'menu-errorList'
        },
        "Templates": { 
            path: '/home/templates',
            icon: 'icon-envelope-open',
            id: 'menu-template'
        },
        "Black list": {
            path: '/home/blacklist',
            icon: 'icon-ban',
            id: 'menu-blacklist'
        },
        "Relatórios": {
            path: '/home/reports',
            icon: 'icon-docs',
            id: 'menu-relatorio',
            subcategory: [
                { subname: 'Domínios mais recorrentes', path: '/home/reports/commondomains', id: 'menu-domains'},
                { subname: 'Estatísticas de envio', path: '/home/reports/sentstats', id: 'menu-statistic' },
                { subname: 'Dados de envio', path: '/home/reports/sendingdata', id: 'menu-sendingdata' } 
            ]
        }, 
        "Usuários": {
            path: '/home/users',
            icon: 'icon-users',
            id: 'menu-users'
        },
        "Configurações": {
            path: "/home/settings",
            icon: 'icon-settings',
            id: 'menu-config'
        }
    }

    // Get the array of keys from 'links' object ['Dashboard', 'API', 'Black list' ...] 
    public linksKeys = Object.keys(this.links);

    // Get the object in the 'Relatórios' field containig subcategories of 'Relatórios'
    // This is used in template
    public subReportsArr = this.links.Relatórios.subcategory; 

    // Get the array of keys from 'links.Relatórios' ['path', 'icon', 'id', 'subcategory']
    public reportsKeys = Object.keys(this.links['Relatórios'])

    private scriptTagName = 'script-nav'
    private scriptPath = 'assets/scripts/navmenu/navmenu.js'

    constructor(
        private userService: AccessService,
        private renderer2: Renderer2,
        private scriptService: ScriptService,
        @Inject(DOCUMENT) private _document) 
    { 
        this.scriptService.renderer2 = this.renderer2;
    }

    close(reason: string) {
        this.sidenav.close();
    }

    ngOnInit() {
        if (this.scriptService.tagExists(this.scriptTagName))
            this.scriptService.deleteTag(this.scriptTagName)
    }

    ngAfterViewInit() {
        this.scriptService.createScriptTag(this.scriptPath, this._document.body, this.scriptTagName)
    }

    googleSignOut() {
        this.userService.signOut();
    }
}
