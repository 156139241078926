import { Component, HostBinding } from '@angular/core'

@Component({
    selector: '[portlet-title]',
    templateUrl: './portlet-title.component.html'
})

export class PortletTitle {
    constructor() { }

    @HostBinding('class') class = 'portlet-title'
}