import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';

// Interfaces to handle data response coming from .NET Core Controllers
import { IGoogleCredentials, IJwtToken } from '../interfaces';
import '../../../rxjs-operators';

import { environment } from '../../../../environments/environment';
const API_ENDPOINT_AUTH = `${environment.apiURL}/api/Auth/Login`;

@Injectable({providedIn: 'root'})
export class AccessService {
    
    private loggedIn = false;

    constructor(private http: HttpClient, private authService: SocialAuthService, private route: Router) {
        this.loggedIn = !!sessionStorage.getItem('validToken');
    }
    
    // Call Google Authentication API (Config file in: /src/googleAuthConfig.ts)
    public singInWithGoogle(): void {
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
            .then(user => {
                this.loginValidation(user)
            })
    }

    // Remove token and user info from sessionStorage. Redirect to /login page.
    public signOut(): void {
        this.removeFromSessionStorage();
        this.loggedIn = false;
        this.authService.signOut();
        this.route.navigate(['/login']);
    }

    // Verify user credentials (tokenNotExpired() method can be used here --> import decode from 'jwt-decode')
    public isLoggedIn() {
        if (this.getToken() == null) {
            this.signOut()
        }
        return this.loggedIn;
    }

    // Get JWT Token from sessionStorage
    public getToken(): string {
        return sessionStorage.getItem('validToken');
    }
    
    // Receives the GoogleAPI response with user info (email, photo, googleID) and
    // sends it to .NET /api/Auth/Login. If user email is registered in 'msgit_users' collection,
    // this method returns a JWT access Token, which is stored in sessionStorage.
    public loginValidation(googleCredetials: SocialUser): void {
        let body: IGoogleCredentials = googleCredetials;  
        this.http.post<IJwtToken>(`${API_ENDPOINT_AUTH}`, body)
            .subscribe(res => { 
                    this.route.navigate([''])
                    this.addToSessionStorage(res.access_Token, `${res.photoUrl}`, res.email, res.name, res.store)
                    this.loggedIn = true;
                },
                _ => {
                    this.loggedIn = false;
                }
            )
    }

    // ** Both method can be refactored **
    private addToSessionStorage(token: string, photo: string, email: string, name: string, store: string) {
        sessionStorage.setItem('store', store)
        sessionStorage.setItem('validToken', token)
        sessionStorage.setItem('userPhoto', photo)
        sessionStorage.setItem('email', email)
        sessionStorage.setItem('name', name)
    }

    private removeFromSessionStorage() {
        sessionStorage.removeItem('store')
        sessionStorage.removeItem('validToken')
        sessionStorage.removeItem('userPhoto')
        sessionStorage.removeItem('email')
        sessionStorage.removeItem('name')
    }
}
    