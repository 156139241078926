<page-nav-ul>
    <page-nav-li [link]="true" path="/home/dashboard" [icon]="true" label="Stampel"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="true" label="Relatórios"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="false" label="Estatísticas de envio"> </page-nav-li>
</page-nav-ul>

<!-- Begin of Angular Form -->
<form #f="ngForm" class="form-horizontal" (ngSubmit)="filterEmails(f)">
    <div class="row">
        <div class="col-md-7">
            <div class="portlet light bordered">
                <div portlet-title>
                    <portlet-caption subject={{title}}> Total: {{total}} </portlet-caption>
                </div>
                <mat-card class="list-filter">
                    <p> PERÍODO </p> 
                    <mat-form-field>
                        <input matInput name="dateStart" [matDatepicker]="dateStart" placeholder="Date Start" [(ngModel)]="defaultFirstDay" [max]="defaultLastDay"> 
                        <mat-datepicker-toggle matSuffix [for]="dateStart"></mat-datepicker-toggle>
                        <mat-datepicker #dateStart></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput name="dateEnd" [matDatepicker]="dateEnd" placeholder="Date End" [(ngModel)]="defaultLastDay" [max]="defaultLastDay"> 
                        <mat-datepicker-toggle matSuffix [for]="dateEnd"></mat-datepicker-toggle>
                        <mat-datepicker #dateEnd></mat-datepicker>
                    </mat-form-field>
                    <button class="btn stampel-color"> GERAR RELATÓRIO </button>
                </mat-card>
            </div>
        </div>
    </div>
</form>

<!-- Begin of Angular Form -->

<div class="row">
    <div class="col-md-7">
        <div class="portlet light bordered">
            <div id="sendingstatistics-panel">
                <div class="grid-mvc" data-lang='en' data-gridname data-slectable='true' data-multiplefilters='false'>
                    <div class='grid-wrap'> 
                        <table class="table table-hover grid-table">
                            <thead> 
                                <tr>
                                    <th width="255px" style="text-align: left; padding-left: 15px;" (click)="sort('dateString')"> Data
                                        <span class="glyphicon" *ngIf="formField == 'dateString'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse }"> </span>     
                                    </th>
                                    <th width="186px" (click)="sort('quantitySent')"> Enviados 
                                        <span class="glyphicon" *ngIf="formField == 'quantitySent'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse }"> </span>
                                    </th>
                                    <th width="373px" (click)="sort('quantityBlocked')"> Bloqueados
                                        <span class="glyphicon" *ngIf="formField == 'quantityBlocked'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse }"> </span>
                                    </th>
                                    <th width="198px" (click)="sort('quantityTotal')"> Total
                                        <span class="glyphicon" *ngIf="formField == 'quantityTotal'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse }"> </span>
                                    </th>
                                </tr> 
                            </thead>
                            <tbody>
                                <tr class="grid-row" *ngFor="let obj of dateArray | orderBy: formField: reverse; let i = index">
                                    <td class="grid-cell" data-name="DataString"> {{ obj.dateString }} </td>
                                    <td class="grid-cell" data-name="QuantitySent"> {{ obj.quantitySent }} </td>
                                    <td class="grid-cell" data-name="QuantityBlocked"> {{ obj.quantityBlocked }} </td>
                                    <td class="grid-cell" data-name="QuantityTotal"> {{ obj.quantityTotal }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
