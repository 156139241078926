import { Component, HostBinding } from '@angular/core'

@Component({
    selector: '[footer]',
    templateUrl: './footer.component.html'
})

export class FooterComponent {

    public year = new Date().getFullYear();
    public version = "2.2.1"
    
    constructor() {

    }

    @HostBinding('class') class = "page-footer stampel-page-footer"
}