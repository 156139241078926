import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplatesService } from '../../services/templates.service';
import { TemplatesModel } from '../../interfaces/templates.interface';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'update-template',
    templateUrl: './update-template.dialog.html',
    styleUrls: [ './update-template.dialog.css' ]
})

export class UpdateTemplateDialog {
    public templateData: TemplatesModel

    constructor(
        @Inject(MAT_DIALOG_DATA) public templateId: any,
        private templateService: TemplatesService
    ) { }

    ngOnInit() {
        this.templateService.getTemplateById(this.templateId)
            .subscribe((httpResponse: TemplatesModel) => {
                this.templateData = httpResponse
            })
    }

    UpdateTemplate(f: NgForm) {
        let form = f.form.value

        let changes = { 
            Title: form.title,
            Body: form.body,
            Subject: form.subject,
            CreateAt: null
        }

        this.templateService.updateTemplate(changes, this.templateId)
            .subscribe(() => {})
    }
}