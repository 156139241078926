import { Injectable } from '@angular/core'

@Injectable()
export class DateHandlerService {
    constructor() { }

    public getFirstDayOfCurrentMonth(): any { 
        let date = new Date()
        return new Date(date.getFullYear(), date.getMonth(), 1)
    }

    public getCurrentDay(): any {
        return new Date(Date.now())
    }

    public AddDays(days: number): any {
        let date = new Date()
        return date.setDate(date.getDate() + days)
    }

    public convertDateToLocaleString(locale: string, date: any): string {
        return new Date(date).toLocaleDateString(locale)
    }
}