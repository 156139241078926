import { Component } from '@angular/core'
import { TemplatesService } from '../services/templates.service'
import { TemplatesModel } from '../interfaces/templates.interface'
import { MatDialog } from '@angular/material/dialog';
import { CreateTemplateDialog, UpdateTemplateDialog, DeleteTemplateDialog, SendTemplateDialog } from '../dialog';

@Component({
    selector: 'templates',
    templateUrl: './templates.component.html'
})

export class TemplatesComponent {
    public templates: TemplatesModel[];

    constructor(
        private templateService: TemplatesService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.templateService.getAllTemplates()
            .subscribe((httpResponse: TemplatesModel[]) => {
                this.templates = httpResponse
            });
    }

    openCreateTemplateDialog() { 
        this.dialog
            .open(CreateTemplateDialog, {})
            .afterClosed().subscribe(() => {
                this.getTemplates()
            })
    }

    openUpdateTemplateDialog(templateId: string) {
        this.dialog
            .open(UpdateTemplateDialog, { data: templateId})
            .afterClosed().subscribe(() => {
                this.getTemplates()
            })
    }

    openDeleteTemplateDialog(templateId: string) {
        this.dialog
            .open(DeleteTemplateDialog, { data: templateId })
            .afterClosed().subscribe(() => {
                this.getTemplates()
            })
    }

    openSendTemplateDialog(templateId: string) {
        this.dialog
            .open(SendTemplateDialog, { data: templateId })
            .afterClosed().subscribe(() => {
                this.getTemplates()
            })
    }

    private getTemplates() {
        this.templateService.getAllTemplates()
            .subscribe((httpResponse: TemplatesModel[]) => {
                this.templates = httpResponse
            })
    }
}