import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'

// Services
import { AccessService } from '../../modules/auth/services'
import { DialogService } from '../services'

@Injectable()
export class ApiRequestsInterceptor implements HttpInterceptor {

    public store: string

    constructor(
        public auth: AccessService, 
        public dialogService: DialogService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = request.clone({
            setParams:{
                "store": sessionStorage.getItem("store")
            },
            setHeaders: {
                'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                'Pragma': 'no-cache',
                'Expires': '0',
                'Authorization': `Bearer ${this.auth.getToken()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                let message: string;
                if (event instanceof HttpResponse) {
                    if (event.url.includes(`api/Blacklist/InsertEmail`)) {
                        message = "Email foi adicionado na blacklist com sucesso!"
                    }
                    else if (event.url.includes("api/Blacklist/DeleteEmail")) {
                        message = "Email deletado com sucesso!"
                    }
                    else if (event.url.includes("api/User/Create")) {
                        message = "Usuário criado com sucesso!"
                    }
                    else if (event.url.includes("api/User/Delete")) {
                        message = "Usuário deletado com sucesso!"
                    }
                    else if (event.url.includes("api/User/Update")) {
                        message = "Usuário atualizado com sucesso!"
                    }
                    else if (event.url.includes("api/Templates/PostTemplate")) {
                        message = "Template criado com sucesso!"
                    }
                    else if (event.url.includes("api/Templates/DeleteTemplate")) {
                        message = "Template deletado com sucesso!"
                    }
                    else if (event.url.includes("api/Templates/UpdateTemplates")) {
                        message = "Template atualizado com sucesso!"
                    }
                    else if (event.url.includes("api/Stores/Create")) {
                        message = "Loja criada com sucesso!"
                    }
                    else if (event.url.includes("api/Stores/Update")) {
                        message = "Loja atualizada com sucesso!"
                    }
                    else if (event.url.includes("api/Stores/DeleteStore")) {
                        message = "Loja deletada com sucesso!"
                    }
                    
                    if (message != null)
                        this.dialogService.displayMessage(message, 'success')
                }
                return event
            }),
            catchError((error: HttpErrorResponse) => {
                let message: string;

                if (error.status == 403)
                    message = `ERROR (${error.status}) | Você não possui permissões`
                else {
                    message = `${error.message}`
                }

                this.dialogService.displayMessage(message, 'error')
                return throwError(error)
            })
        )
    }
}