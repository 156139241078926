import { Component, Inject } from '@angular/core'
import { BlacklistModel } from '../../interfaces/blacklist.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlacklistService } from '../../services/blacklist.service';

@Component({
    selector: 'delete-dialog',
    templateUrl: './delete.component.html'
})

export class DeleteEmailComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: BlacklistModel,
        private blacklistService: BlacklistService
    ) { }
    
    /* "DeleteEmail(id: string)" é chamada ao clicar no ícone de remover item.
        -- Parâmetros: [ id: string ] -> É o identificado do MongoDB (ObjectID).
    Faz uma requisição POST em "https://msgit.bringit.com.br/api/Blacklist/DeleteEmail"
    Após requisição, deleta o elemento <li> da View */
    public DeleteEmail(id: string) { 
        const element = document.getElementById(id);
        this.blacklistService.deleteEmailFromBlacklist(id).subscribe( _ => { 
            element.parentNode.removeChild(element);
        })
    }
}
