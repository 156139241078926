import { Component, Input, HostBinding } from '@angular/core'

@Component({
    selector: 'portlet-caption',
    templateUrl: './caption.component.html'
})

export class PortletCaption {
    @Input() subject: string

    constructor() { }

    // Add a CSS Bootstrap class to wrapper element (in this case 'portlet-caption'. See it devtools)
    @HostBinding("class") class = "caption"
}