<h1 mat-dialog-title> Enviar Template </h1>
<div mat-dialog-content>
    <form #templateForm="ngForm">
        <div class="input-template-container">
            <mat-form-field appearance="outline">
                <mat-label> From: </mat-label>
                <input matInput name="from" ngModel>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label> To: (Múltiplos emails devem ser separados por ';') </mat-label>
                <input matInput name="to" ngModel>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label> Subject: </mat-label>
                <input matInput name="subject" ngModel="{{ templateData?.subject }}">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label> Body: </mat-label>
                <textarea matInput name="body" ngModel="{{ templateData?.body }}"> </textarea>
            </mat-form-field>
        </div>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close> Cancel </button>
    <button mat-button [mat-dialog-close]="false" (click)="SendTemplate(templateForm)"> Enviar </button>
</mat-dialog-actions>