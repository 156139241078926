<h1 mat-dialog-title>Tem certeza que deseja excluir este email? </h1> 
<div mat-dialog-content>
    <dl class="dl-horizontal">
        <dt> ObjectID: </dt>
        <dd> {{ data?.id }} </dd>

        <dt> Email: </dt>
        <dd> {{ data?.email }} </dd>

        <dt> Data de Entrada: </dt>
        <dd> {{ data?.date | date: 'dd/MM/yyyy H:mm:ss' }} </dd>
    </dl>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" (click)="DeleteEmail(data?.id)"> Deletar </button>
</mat-dialog-actions>