<nav class='page-sidebar navbar-collapse collapse'>
    <ul class="page-sidebar-menu" data-keep-expanded="false" data-auto-scroll="true" data-slide-speed="200"> 
        <li *ngFor='let key of linksKeys' class="nav-item" [attr.id]="links[key].id">
            <a *ngIf='key !== "Relatórios"' routerLink={{links[key].path}} routerLinkActive="active"> 
                <i class=" {{links[key].icon}}"></i>
                <span class="title"> {{key}} </span> 
            </a>
            <a *ngIf='key === "Relatórios"' href='javascript:;' class='nav-link nav-toggle'>
                <i class="{{links[key].icon}}"></i>
                <span class='title'> Relatórios</span>
                <span class='arrow'></span> 
            </a>
            <ul *ngIf='key === "Relatórios"' class="sub-menu" style="display: none;">
                <li *ngFor='let obj of subReportsArr' class="nav-item" [attr.id]='obj.id'>
                    <a routerLink={{obj.path}} routerLinkActive="active"> 
                        <span class="title"> {{obj.subname}} </span> 
                    </a>
                </li> 
            </ul>
        </li> 
    </ul>
</nav>
