import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserModel, MapRoles } from '../interfaces/user.interface'
import { environment } from '../../../../../environments/environment';

const API_ENDPOINT_USERS = `${environment.apiURL}/api/User`;

@Injectable()
export class UserService {

    constructor(private http: HttpClient) { }

    // Show users in msgit_users
    showUsers() {
        return this.http.get<UserModel[]>(`${API_ENDPOINT_USERS}/Show`);
    }

    // Show a single user
    showUserById(id: string) { 
        return this.http.get<UserModel>(`${API_ENDPOINT_USERS}/ShowUser?id=${id}`);
    }

    // Get user by email
    showUserByEmail(email: string) {
        return this.http.get<UserModel>(`${API_ENDPOINT_USERS}/ShowUserByEmail?email=${email}`)
    }

    // Get users in the same store
    showUsersByStore(email: string) 
    {
        return this.http.get<UserModel[]>(`${API_ENDPOINT_USERS}/ShowUsersByStore?email=${email}`)
    }

    // Create User
    createUser(userData: UserModel) {      
        return this.http.post(`${API_ENDPOINT_USERS}/Create`, userData);
    }

    // Update an user
    updateUser(userData: UserModel, id: string) {        
        return this.http.post(`${API_ENDPOINT_USERS}/Update?id=${id}`, userData);
    }

    deleteUser(id: string) {
        return this.http.post(`${API_ENDPOINT_USERS}/Delete?id=${id}`, {})
    }

    /* This function creates the user permission based on form values.
       Permissions are separeted by '*' */
    createPermissions(formValues: any, isAdmin: boolean): string { 
        let userPermission = ["User"]

        if (isAdmin) {
            userPermission.push("Admin")
        } else {
            Object.keys(formValues).map(section => { 
                Object.keys(formValues[section]).map(role => { 
                    if (formValues[section][role]) {
                        userPermission.push(this.roles[section][role])
                    }
                })
            })
        }

        return userPermission.join('*')
    }

    mapUserPermissions(permissions: string): any {
        let mapRoles: MapRoles = this.initializeRoles()
        let permissionsArray = permissions.split('*')

        Object.keys(this.roles).map(section => {   
            Object.keys(this.roles[section]).map(role => {
                if (permissionsArray.includes("Admin") || permissionsArray.includes(this.roles[section][role])) {
                    mapRoles[section][role] = true
                }
            })
        })

        return mapRoles
    }

    private initializeRoles(): MapRoles { 
        return {
            api: {
                read: false 
            },
            blacklist: {
                read: false,
                create: false,
                update: false,
                delete: false
            },
            reports: {
                domainRead: false,
                statsRead: false,
                dataRead: false
            },
            users: {
                read: false,
                create: false,
                update: false,
                delete: false
            },
            templates: {
                read: false,
                create: false,
                update: false,
                delete: false
            },
            lojas: {
                read: false
            },
            settings: {
                read: false
            }
        }
    }

    private roles: object = {
        api: { 
            read: 'API' 
        },
        blacklist: {
            read: 'BLR',
            create: 'BLC',
            update: 'BLU',
            delete: 'BLD'
        },
        reports: {
            domainRead: 'RD',
            statsRead: 'RE',
            dataRead: 'RS'
        },
        users: {
            read: 'UR',
            create: 'UC',
            update: 'UU',
            delete: 'UD'
        },
        templates: {
            read: 'TR',
            create: 'TC',
            update: 'TU',
            delete: 'TD'
        },
        lojas: {
            read: 'LR'
        },
        settings: {
            read: 'SR'
        }
    }
}