import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ReportService, DateHandlerService } from '../../services';
import { SentStatsModel } from '../../interfaces/sent-stats.interface';

@Component({
    selector: 'sent-stats',
    templateUrl: './sent-stats.component.html',
    styleUrls: [ './sent-stats.component.css' ]
})

export class SentStatsComponent {
    
    public title: string = "ESTATÍSTICAS DE ENVIO"
    public total: number;
    public defaultFirstDay = new Date();
    public defaultLastDay = new Date();
    public dateArray: SentStatsModel[];

    // Variables used in 'sort()' function
    public formField = "dateString"
    public reverse = false;

    constructor(private reportService: ReportService, private dateService: DateHandlerService) {
        // Initialize the inputs with default values (first day of the month until current day)
        this.defaultFirstDay =  this.dateService.getFirstDayOfCurrentMonth()
        this.defaultLastDay = this.dateService.getCurrentDay()
    }

    ngOnInit() {
        let dateStart = this.dateService.convertDateToLocaleString('pt-BR', this.defaultFirstDay) 
        let dateEnd = this.dateService.convertDateToLocaleString('pt-BR', this.defaultLastDay)

        // HTTP GET: Get Statistics
        this.reportService.getSentStats(dateStart, dateEnd)
            .subscribe((httpResponse: SentStatsModel[]) => {
                this.dateArray = httpResponse;
                this.total = this.totalEmailCalculator(this.dateArray)
            });
    }

    // This function is called after 'Gerar Relatório' button was pressed.
    //      f: Name of the form  defined in the HTML page
    public filterEmails(f: NgForm) {
        let formValue = f.form.value
        let dateStart = this.dateService.convertDateToLocaleString('pt-BR', formValue.dateStart)      // <-- dateStart: Name of the first input field
        let dateEnd = this.dateService.convertDateToLocaleString('pt-BR', formValue.dateEnd)          // <-- dateEnd: Name of the second input field

        this.reportService.getSentStats(dateStart, dateEnd)
            .subscribe((httpResponse: SentStatsModel[]) => {
                this.dateArray = httpResponse;
                this.total = this.totalEmailCalculator(this.dateArray)
            })
    }

    public sort(formField: string) {
        this.reverse = !this.reverse
        this.formField = formField
    }

    private totalEmailCalculator(dateArray: SentStatsModel[]): number {
        return dateArray.reduce((total, row) => {
            return total + row.quantityTotal;
        }, 0)
    }
}