import { Component, Pipe, PipeTransform } from '@angular/core'
import { MatDialog } from '@angular/material/dialog';
import { CreateStoreDialog, UpdateStoreDialog, DeleteStoreDialog } from '../dialog';
import { StoreService } from '../services';
import { StoresModel } from '../interfaces';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'stores',
    templateUrl: './stores.component.html'
})

export class StoresComponent {
    public stores$ = new BehaviorSubject<StoresModel[]>([]);
    
    public page
    public term
    public p
    
    public key: string = "name"
    public reverse: boolean = false

    constructor(
        private dialog: MatDialog,
        private storeService: StoreService     
    ) {}

    ngOnInit() {
        this.storeService.getStores()
            .subscribe((httpResponse: StoresModel[]) => {
                this.stores$.next(httpResponse);
            })
    }

    openCreateStoreDialog() {
        this.dialog
            .open(CreateStoreDialog, {})
            .afterClosed().subscribe(() => { 
                this.getStores()
            })
    }

    openUpdateStoreDialog(id: string) {
        this.dialog
            .open(UpdateStoreDialog, { data: id })
            .afterClosed().subscribe(() => { 
                this.getStores()
            })
    }

    openDeleteStoreDialog(id: string) {
        this.dialog
            .open(DeleteStoreDialog, { data: id })
            .afterClosed().subscribe(() => { 
                this.getStores()
            })
    }

    private getStores() {
        this.storeService.getStores()
            .subscribe((httpResponse: StoresModel[]) => {
                this.stores$.next(httpResponse);
            })
    }

    sort(key) {
        this.key = key
        this.reverse = !this.reverse
    }
}
@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    transform(items: any[], filterBy: string): any[] {
        if (!items) return [];
        if (!filterBy || filterBy.length === 0) return items;
        return items.filter(item => {
            return Object.keys(item).some(key => {
                if (item[key] && typeof item[key] === 'string') {
                    return item[key].toLowerCase().includes(filterBy.toLowerCase());
                }
                return false;
            });
        });
    }
}