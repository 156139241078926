import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { TemplatesService } from '../../services/templates.service';
import { TemplatesModel } from '../../interfaces/templates.interface';

@Component({
    selector: 'send-template',
    templateUrl: './send-template.dialog.html',
    styleUrls: [ './send-template.dialog.css' ]
})

export class SendTemplateDialog {

    public templateData: TemplatesModel

    constructor(
        @Inject(MAT_DIALOG_DATA) public templateId: string,
        private templateService: TemplatesService
    ) {}

    ngOnInit() {
        this.templateService.getTemplateById(this.templateId)
            .subscribe((httpResponse: TemplatesModel) => {
                this.templateData = httpResponse
            })
    }

    SendTemplate(input: NgForm) {
        let value = input.form.value
        
        // Substitui '\n' por <br> para preservar o layout do email.
        let formattedBody = value.body.replace(/\n/ig, "<br>")

        let message = { 
            Sender: value.from,
            Mail: value.to,
            Subject: value.subject,
            Body: formattedBody
        }

        /* Envia um email. Após o envio, redireciona para a página anterior ("/home/templates")
           Endpoint da chamada: "https://msgit.bringit.com.br/api/Message/SendMessage" */
        this.templateService.send(message)
            .subscribe( _ => {})
    }
}