import { Component, Input } from '@angular/core'

@Component({
    selector: 'page-nav-li',
    templateUrl: './page-nav-li.component.html'
})

export class PageNavLiComponent {
    @Input() link: boolean
    @Input() icon: boolean
    @Input() path: string
    @Input() iconName: string
    @Input() label: string

    constructor() {
        
     }
}