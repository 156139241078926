import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../../environments/environment'
import { BlacklistModel } from '../interfaces/blacklist.interface'
import { store } from '../../../../shared/variables/global'

const apiURL = `${environment.apiURL}/api/Blacklist`
const API_ENDOPOINT = `${apiURL}/GetBlacklist`
const API_ENDPOINT_DELETE = `${apiURL}/DeleteEmail`
const API_ENDPOINT_INSERT = `${apiURL}/InsertEmail`

@Injectable()
export class BlacklistService {

    constructor(private http: HttpClient) { }

    public requestBlacklist() { 
        return this.http.get<BlacklistModel[]>(`${API_ENDOPOINT}`)
    }

    // Deletes an email from blacklist
    public deleteEmailFromBlacklist(id: string) { 
        let body = null
        return this.http.post(`${API_ENDPOINT_DELETE}?id=${id}`, body); 
    }

    // Insert email into blacklist
    public insertEmailIntoBlacklist(formEmailField: string) {
        return this.http.post(`${API_ENDPOINT_INSERT}?email=${formEmailField}`, null);
    }
}
