import { Component, Input, HostBinding } from '@angular/core'

@Component({
    selector: 'portlet-action',
    templateUrl: './actions.component.html'
})

export class PortletActions {
    @Input() label: string
    @Input() icon: string
    @Input() path: string
    @Input() btn: string
    @Input() form: boolean

    constructor() { }

    @HostBinding('class') class = 'actions'
}