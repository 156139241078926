<div class='login'>
    <div class='user-login-5'>
        <div class='row bs-reset'>
          <div class='col-md-6 login-container bs-reset' style='background-color: white;'>
            <img class='login-logo login-6' src='assets/images/Stampel_PRINCIPAL.svg' width='300' />
            <div class='login-content'>
              <h1>
                Login
              </h1>
              <p class='login-description'> Acesse com sua conta de e-mail </p>
              <div id="g_id_onload"
                  data-client_id="442962591213-a952c4b1849qkakql8jv38i1jlgeupe8.apps.googleusercontent.com"
                  data-context="signin"
                  data-ux_mode="popup"
                  data-callback="handleCredentialResponse"
                  data-nonce=""
                  data-auto_select="true"
                  data-close_on_tap_outside="false"
                  data-itp_support="true">
              </div>

              <div class="g_id_signin"
                  data-type="standard"
                  data-shape="rectangular"
                  data-theme="outline"
                  data-text="signin_with"
                  data-size="large"
                  data-logo_alignment="left">
              </div>


            </div>
            <div class='login-footer'>
              <div class='row bs-reset'>
                <div class='col-xs-12 bs-reset'>
                  <div class='login-copyright text-right'>
                    <img src='assets/images/logo-nordware.jpg' />
                    <p> Versão 2.2.1 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div class='col-md-6 bs-reset'> 
                <div class='login-bg'>
                    
                </div>
            </div>
        </div>
    </div>
</div>
