import { NgModule } from '@angular/core'
import { PageNavLiComponent, PageNavUlComponent } from '.'
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        RouterModule,
        CommonModule
    ],
    declarations: [
        PageNavLiComponent,
        PageNavUlComponent
    ],
    exports: [
        PageNavLiComponent,
        PageNavUlComponent
    ]
})

export class PageNavModule { }
