import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable()
export class ScriptService { 
    public renderer2: Renderer2
    
    private _tag;
    constructor(rendererFactory: RendererFactory2) {
        this.renderer2 = rendererFactory.createRenderer(null, null);
    }

    public createScriptTag(path: string, dom: any, idTagName: string): void {
        this._tag = this.renderer2.createElement('script');
        this._tag.type = 'text/javascript';
        this._tag.src = `${path}`;
        this._tag.text = ``;
        this.renderer2.setProperty(this._tag, "id", idTagName);
        this.renderer2.appendChild(dom, this._tag);
    }

    public tagExists(idTagName: string): boolean {
        if (document.getElementById(idTagName))
            return true;
        return false;
    }

    public deleteTag(idTagName: string) {
        document.getElementById(idTagName).remove();
    }
}
