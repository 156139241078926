import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { EChartsOption } from 'echarts';
import { DashboardService } from '../services/dashboard.service'
import { PeriodStatsModel, ChartStatsModel } from '../interfaces/dashboard.interface'

import { FormGroup, FormControl } from '@angular/forms'
import { StoreService } from '../../stores/services'
import { StoresModel } from '../../stores/interfaces'
import { BlackStatsService } from '../services/blstats.service';
import { BlackStatsModel } from '../interfaces/blstats.interface';
import { UserService } from '../../users/services';
import { UserModel } from '../../users/interfaces';

const DEFAULT_PERIOD = "7days";

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  providers: [ DashboardService ]
})

export class DashboardComponent implements OnInit {
  public blacklistStats: BlackStatsModel
  public stores: StoresModel[]
  public options: EChartsOption
  public period: string
  public stats: PeriodStatsModel 
  public topReceivers;
  public topSubjects;
  public currentUser: UserModel;
  
  public form: FormGroup = new FormGroup({
    stores: new FormControl('')
  })

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute, 
    private dashboardService: DashboardService,
    private blacklistService: BlackStatsService,
    private spinner: NgxSpinnerService,
    private storeService: StoreService,
  ) { }

  ngOnInit() {
      let currentEmail = sessionStorage.getItem('email')
      this.userService.showUserByEmail(currentEmail)
        .subscribe((httpResponse: UserModel) => { 
            this.currentUser = httpResponse
            if (this.currentUser.admin == 1) { 
                this.storeService.getStores()
                  .subscribe((httpResponse: StoresModel[]) => { 
                      this.stores = httpResponse
          
                      let currentStore = sessionStorage.getItem('store')
                      this.form.controls['stores'].setValue(currentStore)
                  })
            }
        })

      this.LoadBlacklistStatistics()
      this.LoadPeriodStatistics()
      this.onChanges()
  }

  convertObjectToArray(obj: object) {
    return Object.keys(obj).map((key) => {
      return { email: key, count: obj[key] }
    })
  }

  private LoadPeriodStatistics() { 
    this.activatedRoute.params.subscribe(params => {
        let period = params.period == null ? DEFAULT_PERIOD : params.period;
        this.period = this.dashboardService.getPeriodString(period);
        
        this.spinner.show()
        this.dashboardService.getPeriodStats(period).subscribe((httpResponse: PeriodStatsModel) => {
            if (httpResponse == null) {
                httpResponse = this.fillPeriodStatsWithDefaultValues()
            }
            this.stats = httpResponse;
            this.topReceivers = this.convertObjectToArray(httpResponse.topFourReceivers)
            this.topSubjects = this.convertObjectToArray(httpResponse.topFourSubjects)
            this.options = this.getChartOptions(httpResponse.chartData);
            this.spinner.hide()
        }, _ => this.spinner.hide())
    })  
  }

  private LoadBlacklistStatistics() {
    this.blacklistService.getBlacklistStats()
        .subscribe((httpResponse: BlackStatsModel) => {
            if (httpResponse == null) {
                httpResponse = this.fillBlacklistStatsWithDefaultValues()
            }
            this.blacklistStats = httpResponse
        }, _ => {})
  }

  getChartOptions(data: any) {
    let options: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            showContent: true
        },
        color: ['rgba(30, 37, 67, 0.9)','rgba(143, 83, 225, 0.9)', 'rgba(134,155,98,0.8)'],
        legend: {
            data: ['Enviados', 'Bloqueados']
        },
        xAxis: {
            type: 'category',
            data: data.date,
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            // SENT ---
            name: 'Enviados',
            type: 'bar',
            // sent data
            data: data.totalSent,
            // sent markpoint
            markPoint: {
                symbol: 'pin',
                symbolSize: 50,
                data: [
                    { value: data.maxValueSent, name: 'Máximo', xAxis: data.maxIndexSent,  yAxis: data.maxValueSent },
                    { value: data.minValueSent, name: 'Mínimo', xAxis: data.minIndexSent,  yAxis: data.minValueSent }
                ],
            },
            // sent markline
            markLine: {
                data : [
                    [
                        {name: 'Média: ', value: data.avgSent.toFixed(0), xAxis: -1, yAxis: data.avgSent},
                        {name: '', xAxis: 270, yAxis: data.avgSent}
                    ]
                ]
            }
        },
        {
            // BLACKLIST ---
            name: 'Bloqueados',
            type: 'bar',
            // blacklist data
            data: data.totalBlacklist,
            // blacklist markpoint
            markPoint: {
                symbol: 'pin',
                symbolSize: 50,
                data: [
                    { value: data.maxValueBlocked, name: 'Máximo', xAxis: data.maxIndexBlocked,  yAxis: data.maxValueBlocked },
                    { value: data.minValueBlocked, name: 'Mínimo', xAxis: data.minIndexBlocked,  yAxis: data.minValueBlocked }
                ]
            },
            // blacklist markline
            markLine: {
                data: [
                    [
                        { name: 'Média: ', value: data.avgBlocked.toFixed(0), xAxis: -1, yAxis: data.avgBlocked },
                        { name: '', xAxis: 270, yAxis: data.avgBlocked }
                    ]
                ]
            }
        }
        ]
    };

    return options;
  }

  public removeSpacesAndToLower(storeName: string) {
    return storeName
        .toLowerCase()
        .replace(" ", "")
  }

  public onChanges(): void { 
      this.form.valueChanges
        .subscribe(val => {
            sessionStorage.setItem('store', val["stores"]) 
            this.LoadPeriodStatistics()  
            this.LoadBlacklistStatistics()        
        })
      
  }

  private fillPeriodStatsWithDefaultValues(): PeriodStatsModel {
      let date = new Date()
      let currentDate = date.toLocaleDateString('pt-BR')

      let model: PeriodStatsModel = {
        dateStart: currentDate,
        dateEnd: currentDate,
        periodName: "7days",
        topFourReceivers: {
            ["Não há registros"]: 0
        },
        topFourSubjects: { 
            ["Não há registros"]: 0
        },
        subNameDict: { 
            ["Não há registros"]: ""
        },
        chartData: this.fillChartStatsWithDefaultValues()
      }

      return model
  }

  private fillChartStatsWithDefaultValues(): ChartStatsModel {
      let date = new Date()

      let model: ChartStatsModel = {
        avgBlocked: 0,
        avgSent: 0,
        date: [
            this.AddDays(-6), 
            this.AddDays(-5),
            this.AddDays(-4), 
            this.AddDays(-3),
            this.AddDays(-2), 
            this.AddDays(-1),
            date.toLocaleDateString('pt-BR')
        ],
        maxIndexBlocked: 0,
        maxIndexSent: 0,
        maxValueBlocked: 0,
        maxValueSent: 0,
        minIndexBlocked: 0,
        minIndexSent: 0,
        minValueBlocked: 0,
        minValueSent: 0,
        totalBlacklist: [
            0, 0, 0, 0, 0, 0, 0
        ],
        totalSent: [
            0, 0, 0, 0, 0, 0, 0
        ],
      }

      return model
  }

  private fillBlacklistStatsWithDefaultValues(): BlackStatsModel {
      
      let model: BlackStatsModel = { 
        totalCurrentYear: 0,
        totalCurrentMonth: 0,
        totalLastMonth: 0,
        totalPreLastMonth: 0,
        totalCurrentDay: 0,
        totalGeneral: 0,
        currentYearPercent: 0,
        currentLastMonthPercent: 0,
        lastPreLastMonthPercent: 0,
        period: "7days",
        currentYear: 0,
        currentMonth: "",
        lastMonth: "",
        preLastMonth: ""
      }

      return model
  }

  private AddDays(day: number) {
    let date = new Date()
    return new Date(date.setDate(date.getDate() + day)).toLocaleDateString('pt-BR')
  }

};