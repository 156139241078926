import { NgModule } from '@angular/core'
import { SharedModule } from '../../../shared/shared.module'

import { TemplatesComponent } from './page'

import { CreateTemplateDialog, UpdateTemplateDialog, DeleteTemplateDialog, SendTemplateDialog } from './dialog';
import { TemplatesService } from './services/templates.service';

@NgModule({
    declarations:[
        TemplatesComponent,
        CreateTemplateDialog,
        UpdateTemplateDialog,
        DeleteTemplateDialog,
        SendTemplateDialog
    ],
    imports: [
        SharedModule
    ],
    providers: [
        TemplatesService
    ]
})

export class TemplatesModule {}