import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlacklistService } from '../../services/blacklist.service';

@Component({
    selector: 'create-dialog',
    templateUrl: 'create.dialog.html'
})

export class CreateEmailComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        private blacklistService: BlacklistService
    ) { }

     /* "InsertEmail(f: NgForm)" é chamado ao clicar no botão "Salvar".
           -- Parâmetros: [ f: NgForm ] -> É o formulário definido no template HTML.
        Faz uma requisição POST em "https://msgit.bringit.com.br/api/Blacklist/InsertEmail" */
    InsertEmail(email: string) { 
        this.blacklistService.insertEmailIntoBlacklist(email)
            .subscribe(_ => {})
    }
}