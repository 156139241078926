<div class="row">
    <div class="col-sm-12 col-lg-12 col-md-12 col-sm-12">
        <div class="portlet light portlet-fit bordered">
            <div class="portlet-title" style="margin-bottom:0px; padding-bottom:5px; padding-top:8px;">
                <div class="caption">
                    <i class="icon-layers font-blue-bringit"></i>
                    <span class="caption-subject font-blue-bringit bold uppercase">Relação de emails nos últimos {{period}}</span>
                </div>
            </div>
            <div class="portlet-body" style="padding-bottom:0px;">
                <div echarts [options]='options' id="echarts_line" style="height: 330px; -moz-user-select: none; background-color: transparent; cursor: default;">
                    
                </div>
            </div>
        </div>
    </div>
</div>