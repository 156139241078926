import { NgModule } from '@angular/core'
import { ApiComponent } from './page'

import { SharedModule } from '../../../shared/shared.module'

@NgModule({
    declarations: [
        ApiComponent
    ],
    imports: [ 
        SharedModule
    ]
})

export class ApiModule {}