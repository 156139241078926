<page-nav-ul>
    <page-nav-li [link]="true" path="/home/dashboard" [icon]="true" label="Stampel"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="false" label="Usuários"> </page-nav-li>
</page-nav-ul>

<div class="portlet light bordered">
    <div portlet-title>
        <portlet-caption subject="Usuários"> Pessoas com acesso ao sistema e suas respectivas permissões e restrições. </portlet-caption>
        <portlet-action (click)="openCreateUserDialog()" btn="btn stampel-color pull-right" icon="fa fa-plus"> Novo Usuário </portlet-action>
    </div>
    <div class="portlet-body">
        <ng-container *ngIf="(userList$ | async) && (userList$ | async)?.length > 0"> 
            <div class="table">
                <div class="dataTables_wrapper no-footer">
                    <div class='row'>
                        <div class='col-md-6 col-sm-6'> </div>
                        <div class='col-md-6 col-sm-6'> 
                            <div style="text-align: right">
                                <label> 
                                    Filtrar:
                                    <input type="text" [(ngModel)]="term" class="form-control input-sm input-small input-inline" placeholder="Search User">
                                </label> 
                            </div>
                        </div>
                    </div> 
                    <div class="table-scrollable">
                        <table class="table table-hover dataTable no-footer" id="datatable-users">
                            <thead>
                                <tr>
                                    <th (click)="sort('name')" width="30%"> Nome 
                                        <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                                    </th>
                                    <th width="30%" (click)="sort('email')"> Email 
                                        <span class="glyphicon sort-icon" *ngIf="key =='email'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                                    </th>
                                    <th width="15%"> Loja </th>
                                    <th width="10%"> </th>
                                    <th width="15%"> </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor='let user of (userList$ | async | filterBy: term) | orderBy: key: reverse | paginate: { itemsPerPage: 10, currentPage: p }' [attr.id]='user.id'>
                                    <td> {{ user?.name }} </td>
                                    <td> {{ user?.email }} </td>
                                    <td> {{ user?.store }}</td>
                                    <td>
                                        <span *ngIf='user.active == 1' class="label label-success">ATIVO</span>
                                        <span *ngIf='user.active == 0' class="label label-danger">INATIVO</span>
                                        <span *ngIf='user.admin == 1' class="label label-info" style="margin-left: 5px;"> ADMIN </span>
                                    </td>
                                    <td class="align-right">
                                        <a (click)="openUpdateUserDialog(user.id)" class="btn btn-default" title="Editar"><span class="fa fa-pencil"></span></a>
                                        <a (click)="openDeleteUserDialog(user.id)" class="btn btn-danger" title="Excluir"><span class="fa fa-trash"></span></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <pagination-controls (pageChange)="p = $event"> </pagination-controls>
            </div>
        </ng-container>
        <ng-container *ngIf="(userList$ | async) && (userList$ | async)?.length === 0">
            <p style="text-align: center;"> Não há usuários cadastrados para esta loja! </p> 
        </ng-container>
    </div>
</div>
