import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../services';
import { UserModel } from '../../interfaces';

@Component({
    selector: 'delete-user',
    templateUrl: './delete-user.dialog.html'
})

export class DeleteUserDialog {
    public user: UserModel

    constructor(
        @Inject(MAT_DIALOG_DATA) public userId: string,
        private userService: UserService
    ) {}

    ngOnInit() { 
        this.userService.showUserById(this.userId)
            .subscribe((httpResponse: UserModel) => {
                this.user = httpResponse
            })
    }

    public DeleteUser() { 
        this.userService.deleteUser(this.userId)
            .subscribe(_ => { })
    }
}