import { NgModule } from '@angular/core'
import { NgxEchartsModule } from 'ngx-echarts'
import { SharedModule } from '../../../shared/shared.module'
import { StoreService } from '../stores/services'
import { BlackStatsService } from './services/blstats.service';


 
import { 
    BlackStatsComponent,
    GraphicComponent,
    PeriodStatsComponent,
    DashboardComponent 
} from './page'


@NgModule({
    declarations:[
        BlackStatsComponent,
        GraphicComponent,
        PeriodStatsComponent,
        DashboardComponent
    ],
    imports: [
        NgxEchartsModule,
        SharedModule
    ],
    providers: [
        StoreService,
        BlackStatsService
    ]
})

export class DashboardModule {}