<div class='col-sm-12 col-lg-4 col-md-12 col-sm-12'>
  <!-- First Column; FirstSquare -->
  <div class='dashboard-stat2 bordered'>
      <div class="display">
          <div class="number" style="width: 100%">
              <div class="icon">
                  <i class="icon-bar-chart"></i>
              </div>
              <h3 class="font-blue-bringit">
                  <span data-counter="counterup" [attr.data-value]="stats?.totalGeneral"> {{stats?.totalGeneral}} </span>
              </h3>
              <small>TOTAL DE ENDEREÇOS DE EMAIL NA BLACKLIST</small>
          </div>
      </div>
      <div class="progress-info">
          <div class="progress">
              <span [ngStyle]="{'width': stats?.currentYearPercent + '%'}" class="progress-bar progress-bar-success bg-blue-bringit">
              </span>
          </div>
          <div class="status">
              <div class="status-title">{{stats?.totalCurrentYear}} ENDEREÇOS DE EMAIL ENCAMINHADOS PARA A BLACKLIST EM {{stats?.currentYear}}</div>
              <div class="status-number"> {{stats?.currentYearPercent}}% </div>
          </div>
      </div>
  </div>
  <!-- First Column; Second Square -->
  <div class="dashboard-stat2 bordered">
      <div class="display">
          <div class="number" style="width: 100%">
              <div class="icon">
                  <i class="icon-bar-chart"></i>
              </div>
              <h3 class="font-blue-bringit">
                  <span data-counter="counterup" [attr.data-value]="stats?.totalLastMonth">  {{stats?.totalLastMonth}} </span>
              </h3>
              <small>ENDEREÇOS DE EMAIL NA BLACKLIST EM  {{stats?.lastMonth}} </small>
          </div>
      </div>
      <div class="progress-info">
          <div class="progress">
              <span [ngStyle]="{'width': stats?.lastPreLastMonthPercent + '%'}" class="progress-bar progress-bar-success bg-blue-bringit">
              </span>
          </div>
          <div class="status">
              <div class="status-title">PROGRESSO EM RELAÇÃO A  {{stats?.preLastMonth}} </div>
              <div class="status-number">  {{stats?.lastPreLastMonthPercent}}% </div>
          </div>
      </div>
  </div>
  <!-- First Column; Third Square -->
  <div class="dashboard-stat2 bordered">
      <div class="display">
          <div class="number" style="width: 100%">
              <div class="icon">
                  <i class="icon-bar-chart"></i>
              </div>
              <h3 class="font-blue-bringit">
                  <span data-counter="counterup" [attr.data-value]="stats?.totalCurrentMonth"> {{stats?.totalCurrentMonth}} </span>
              </h3>
              <small>ENDEREÇOS DE EMAIL NA BLACKLIST EM {{stats?.currentMonth}}</small>
          </div>
      </div>
      <div class="progress-info">
          <div class="progress">
              <span [ngStyle]="{'width': stats?.currentLastMonthPercent + '%'}" class="progress-bar progress-bar-success bg-blue-bringit">
              </span>
          </div>
          <div class="status">
              <div class="status-title">PROGRESSO EM RELAÇÃO A {{stats?.lastMonth}} </div>
              <div class="status-number"> {{stats?.currentLastMonthPercent}}% </div>
          </div>
      </div>
  </div>
</div>
