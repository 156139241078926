import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { StoresModel } from '../interfaces';
import { environment } from '../../../../../environments/environment';

const API_ENDPOINT_STORES = `${environment.apiURL}/api/Stores`;

@Injectable()
export class StoreService {
  
  constructor(
    private http: HttpClient
  ) {}

  // Show Stores
  getStores() {
    return this.http.get<StoresModel[]>(`${API_ENDPOINT_STORES}/GetStores`);
  }

  // Get a single store
  getStoreById(id: string) {
    return this.http.get<StoresModel>(`${API_ENDPOINT_STORES}/GetStoreById?id=${id}`);
  }

  // Creates a store 
  createStore(store: StoresModel) {
    return this.http.post(`${API_ENDPOINT_STORES}/Create`, store);
  }

  // Updates a Store
  updateStore(store: StoresModel, id: string) {
    return this.http.post(`${API_ENDPOINT_STORES}/Update?id=${id}`, store)
  }

  deleteStore(id: string) {
    return this.http.post(`${API_ENDPOINT_STORES}/DeleteStore?id=${id}`, {})
  }

}
