<page-nav-ul>
    <page-nav-li [link]="true" path="/home/dashboard" [icon]="true" label="Stampel"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="false" label="Lojas"> </page-nav-li>
</page-nav-ul>
<div class="portlet light bordered"> 
    <div portlet-title> 
        <portlet-caption subject="Lojas"> Lojas cadastradas que utilizam o Stampel </portlet-caption>
        <portlet-action (click)="openCreateStoreDialog()" btn="btn stampel-color pull-right" icon="fa fa-plus"> Nova Loja </portlet-action>
    </div>
    <div class="portlet-body">
        <div class="table">
            <div class="dataTables_wrapper no-footer">
                <div class='row'>
                    <div class='col-md-6 col-sm-6'> </div>
                    <div class='col-md-6 col-sm-6'> 
                        <div style="text-align: right">
                            <label> 
                                Filtrar:
                                <input type="text" [(ngModel)]="term" class="form-control input-sm input-small input-inline" placeholder="Search User" >
                            </label> 
                        </div>
                    </div>
                </div> 
                <div class="table-scrollable">
                    <table class="table table-hover dataTable no-footer" id="datatable-users">
                        <thead>
                            <tr>
                                <th (click)="sort('name')"> Nome 
                                    <span class="glyphicon sort-icon" *ngIf="key =='name'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                                </th>
                                <th width="20%" (click)="sort('website')"> Website 
                                    <span class="glyphicon sort-icon" *ngIf="key =='website'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                                </th>
                                <th width="30%" (click)="sort('email')"> Email
                                    <span class="glyphicon sort-icon" *ngIf="key =='email'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
                                </th>
                                <th width="80px">  </th>
                                <th width="200px">  </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor='let store of (stores$ | async | filterBy: term) | orderBy: key: reverse | paginate: { itemsPerPage: 10, currentPage: p }' [attr.id]='store.id'>
                                <td> {{ store?.name }} </td>
                                <td> {{ store?.website }} </td>
                                <td> {{ store?.email }} </td>
                                <td>
                                    <span *ngIf='store.active == 1' class="label label-success"> ATIVO </span>
                                    <span *ngIf='store.active == 0' class="label label-danger"> INATIVO </span>
                                </td>
                                <td class="align-right">
                                    <a (click)="openUpdateStoreDialog(store.id)" class="btn btn-default" title="Editar"><span class="fa fa-pencil"></span></a>
                                    <a (click)="openDeleteStoreDialog(store.id)" class="btn btn-danger" title="Excluir"><span class="fa fa-trash"></span></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <pagination-controls (pageChange)="p = $event"> </pagination-controls>
        </div>
    </div>
</div>
