import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { APIViewModel } from '../interfaces/api.interface'
import { environment } from '../../../../../environments/environment'

const API_ENDPOINT_APISECTION = `${environment.apiURL}/api`;

@Injectable()
export class ApiService {
    
    constructor(private http: HttpClient) {
        
    }

    getApiInformation() { 
        return this.http.get<APIViewModel>(`${API_ENDPOINT_APISECTION}`)
    }

}