<page-nav-ul>
    <page-nav-li [link]="true" path="/home/dashboard" [icon]=true label="Stampel"> </page-nav-li>
    <page-nav-li [link]="false" [icon]=false label="Configurações"> </page-nav-li>
</page-nav-ul>

<div class="row">
    <div class="col-md-6">
        <div class="portlet light bordered">
            <div portlet-title>
                <portlet-caption subject="Configurações"> Dados de Acesso </portlet-caption>
            </div>
            <div class="portlet-body">
                <div class="form-horizontal">
                    <div class="form-group">
                        <label class="col-sm-2">Endereço: </label>
                        <div class="col-sm-10">
                            <label> 192.168.4.46 </label>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="col-sm-2">Porta: </label>
                        <div class="col-sm-10">
                            <label> 587 </label>
                        </div>
                    </div>       
                    <div class="form-group">
                        <label class="col-sm-2">Autenticação: </label>
                        <div class="col-sm-10">
                            <label> Senha não segura </label>
                            <p><small><i>Solicite usuário e senha ao departamento de TI</i></small></p>
                        </div>
                    </div>                     
                </div>
            </div>
        </div>
    </div>
</div>
