<h1 mat-dialog-title> Confirme o email que será adicionado à blacklist: </h1>
<div mat-dialog-content> 
    <dl class="dl-horizontal">
        <dt> Email: </dt>
        <dd> {{ data }} </dd>
    </dl>
</div>
<mat-dialog-actions align='end'> 
    <button mat-button mat-dialog-close> Cancel </button>
    <button mat-button [mat-dialog-close]="true" (click)="InsertEmail(data)"> Inserir </button>
</mat-dialog-actions>