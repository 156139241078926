<!-- Second Block Dashboard -->
<div class="col-sm-12 col-lg-4 col-md-12 col-sm-12">
    <div class="portlet light bordered" style="min-height: 1px; padding-bottom: 25px;">
        <div class="portlet-title receiver">
            <div class="caption">
                <i class="icon-envelope font-blue-bringit"></i>
                <span class="caption-subject bold uppercase font-blue-bringit">TOP 4 assuntos nos últimos {{period}} </span>
            </div>
        </div>
        <div class="mt-element-list">
            <div class="mt-list-container list-default row">
                <ul>
                    <li class="mt-list-item" *ngFor='let item of topSubjects'>
                        <div class="list-icon-container ">
                            <i class="icon-envelope-open font-blue-bringit"></i>
                        </div>
                        <div class="list-qtde" style="font-size: .8em;">
                            <h5 class="font-blue-bringit">
                                <span class="bold" data-counter="counterup" [attr.data-value]="item.count"> {{item.count}} </span>
                                <span style="font-weight: 400; color: #aaaaaa;">
                                    e-mails
                                </span>
                            </h5>
                        </div>
                        <div class="list-item-content" style="font-size: .8em">
                            <h5 style="font-weight: 400">
                                {{stats.subNameDict[item.email]}}
                            </h5>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="col-sm-12 col-lg-4 col-md-12 col-sm-12">
    <div class="portlet light bordered" style="min-height: 1px; padding-bottom: 25px;">
        <div class="portlet-title receiver">
            <div class="caption">
                <i class="icon-user font-blue-bringit"></i>
                <span class="caption-subject bold uppercase font-blue-bringit">TOP 4 destinatários nos últimos {{period}} </span>
            </div>
        </div>
        <div class="mt-element-list">
            <div class="mt-list-container list-default row">
                <ul>
                    <li class="mt-list-item" *ngFor='let item of topReceivers'>
                        <div class="list-icon-container ">
                            <i class="icon-user font-blue-bringit"></i>
                        </div>
                        <div class="list-qtde" style="font-size: .8em;">
                            <h5 class="font-blue-bringit">
                                <span class="bold" data-counter="counterup" [attr.data-value]="item.count"> {{item.count}} </span>
                                <span style="font-weight: 400; color: #aaaaaa;">
                                    e-mails
                                </span>
                            </h5>
                        </div>
                        <div class="list-item-content" style="font-size: .8em">
                            <h5 style="font-weight: 400">
                                {{item.email}}
                            </h5>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
    