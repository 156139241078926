import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { QueueModel } from '../interfaces/queue.interface'

import { environment } from '../../../../../environments/environment'
const apiURL = environment.apiURL;
const API_ENDPOINT_QUEUE = `${apiURL}/api/Queue/GetEmails`

@Injectable()
export class QueueService {

    constructor(
        private http: HttpClient
    ) { }

    public requestEmailsInQueue() {
        return this.http.get<QueueModel>(API_ENDPOINT_QUEUE)
    }
}