<h1 mat-dialog-title> Cadastro de template </h1>
<div mat-dialog-content>
    <form #templateForm="ngForm">
        <div class="input-template-container">
            <mat-form-field appearance="outline">
                <mat-label> Título do Template: </mat-label>
                <input matInput name="title" ngModel>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label> Subject: </mat-label>
                <input matInput name="subject" ngModel>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label> Body: </mat-label>
                <textarea matInput name="body" ngModel> </textarea>
            </mat-form-field>
        </div>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close> Cancel </button>
    <button mat-button [mat-dialog-close]="false" (click)="PostTemplate(templateForm)"> Criar </button>
</mat-dialog-actions>