/* Neste arquivo são definidas as rotas após o login. 
   Ele deve ser importado pelo módulo 'home.module.ts' e deve 
   ser declarado em: 
   @NgModule({ 
       imports: [
           ..., 
           RouterModule.forChild(HOME_ROUTES) 
        ],
        ... 
    })
*/

import { Routes } from '@angular/router'

/* Importa os componentes do diretório "app/Home" */
   import { DashboardComponent } from './dashboard/page'
   import { UsersComponent } from './users/page'
   import { LayoutComponent } from './layout/page'
   import { SettingsComponent } from './settings/page'
   import { SendingDataComponent, SentStatsComponent, CommonDomainsComponent } from './reports/page'
   import { QueueComponent } from './queue/page'
   import { TemplatesComponent } from './templates/page' 
   import { ApiComponent } from './api/page'
   import { BlacklistComponent } from './blacklist/page'
   import { StoresComponent } from './stores/page'
   import { ErrorListComponent } from './errorList/page'

/* Este módulo verifica se um usuário está autenticado.
   Se não estiver, a rota não poderá ser acessada. */
import { AuthGuard } from '../auth/services/auth-guard.service'

export const HOME_ROUTES: Routes = [
    { 
        path: 'home',
        component: LayoutComponent,
        canActivate: [ AuthGuard ], // <-- Verifica se o usário está autenticado.
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'api', component: ApiComponent },
            { path: 'stores', component: StoresComponent },
            { path: 'queue', component: QueueComponent },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'dashboard/:period', component: DashboardComponent },
            { path: 'blacklist', component: BlacklistComponent },
            { path: 'errorList', component: ErrorListComponent },
            { path: 'reports/sentstats', component: SentStatsComponent },
            { path: 'reports/commondomains', component: CommonDomainsComponent },
            { path: 'reports/sendingdata', component: SendingDataComponent },
            { path: 'users', component: UsersComponent },
            { path: 'templates', component: TemplatesComponent },
            { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]}
        ]
    }
]
