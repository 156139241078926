<div class='page-container-bg-solid page-header-fixed page-sidebar-closed-hide-logo page-md'>
    <div header>  </div>
    <div class='page-container'> 
        <div class='page-sidebar-wrapper'> 
            <nav-menu> </nav-menu>
        </div>
        <div class='page-content-wrapper'>
            <div class='page-content' style="min-height: 781px;">
                <router-outlet> </router-outlet>
            </div>
        </div>
    </div>
</div>
<div footer> </div>
<ngx-spinner 
    bdColor = "rgba(51, 51, 51, 0.6)" 
    size = "default" 
    color="white" 
    type = "ball-spin" 
    fullScreen = "false" > 
</ngx-spinner>
