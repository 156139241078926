/* Este módulo mostra caixas de diálogo ao usuário, após requisições ao servidor.
   Ele é utilizado no interceptador de requisições HTTP ('src/app/Shared/Interceptors/api.interceptor.ts').
   Ex: Ao deletar um email da blacklist, este serviço mostra um 'snackBar' com a mensagem 'Email deletado com sucesso!' */

import { Injectable, NgZone } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'

@Injectable()
export class DialogService {

    constructor(
        public snackBar: MatSnackBar, 
        private zone: NgZone
    ) {}
    
    /* Parâmetros: 
        [ message ]: "Mensagem que será mostrada ao usuário"
        [ cssClass ]: "Classe CSS para estilizar a caixa de diálogo" 
        Obs: As classes CSS podem ser criadas no arquivo "ClientApp/src/app/styles.css" */
    displayMessage(message: string, cssClass: string): void {
        this.zone.run(() => {
            let config = new MatSnackBarConfig();
            let duration = 3000
            
            config = {
                duration: duration,
                panelClass: [ cssClass ]
            }

            this.snackBar.open(message, null, config)
        })
    }
 }