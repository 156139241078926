import { Injectable } from "@angular/core"
import { HttpClient } from '@angular/common/http'
import { CommonDomainsModel } from '../interfaces/common-domains.interface'
import { SentEmailsModel } from '../interfaces/sending-data.interface'
import { SentStatsModel } from '../interfaces/sent-stats.interface';

import { environment } from '../../../../../environments/environment';
const apiURL = `${environment.apiURL}/api/Reports`
const API_ENDPOINT_COMMONDOMAINS = `${apiURL}/CommonDomains`
const API_ENDPOINT_SENDINGDATA = `${apiURL}/SendingData`
const API_ENPOINT_SENTSTATS = `${apiURL}/SentEmailsStatistics`

@Injectable()
export class ReportService {

    constructor(private http: HttpClient) {}

    public getCommonDomains() { 
        return this.http.get<CommonDomainsModel[]>(API_ENDPOINT_COMMONDOMAINS)
    }

    public getSentData(queryParameter: string = "") {
        return this.http.get<SentEmailsModel[]>(`${API_ENDPOINT_SENDINGDATA}?${queryParameter}`)
    }

    public getSentStats(dateStart: string, dateEnd: string) { 
        return this.http.get<SentStatsModel[]>(`${API_ENPOINT_SENTSTATS}?start=${dateStart}&end=${dateEnd}`)
    }
}