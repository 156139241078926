import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';

import { BlacklistComponent, FilterByPipe } from './page';
import { BlacklistService } from './services/blacklist.service';
import { CreateEmailComponent, DeleteEmailComponent, DiagnosticComponent } from './dialog';

@NgModule({
  declarations: [
    BlacklistComponent,
    DeleteEmailComponent,
    CreateEmailComponent,
    DiagnosticComponent,
    FilterByPipe
  ],
  imports: [
    SharedModule
  ],
  providers: [
    BlacklistService
  ]
})
export class BlacklistModule {}
